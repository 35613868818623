import React, { useEffect, useState } from "react";
import "./Facturation.scss";

import { Link, useNavigate, useParams } from "react-router-dom";
import Tabs from "../../components/Tabs";
import ListesFactures from "./ListesFactures/ListesFactures";
import ListesAvoirs from "./ListesAvoirs/ListesAvoirs";
import ListeDevis from "./ListeDevis/ListeDevis";
import {
  Company,
  IAvoir,
  IDevis,
  IFacture,
  IModel,
  ISubCategory,
  User,
} from "../../interfaces";
import { useSelector } from "react-redux";
import useAxios from "../../util/hooks/useAxios";
import {
  Badge,
  Button,
  Card,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import BannerTop from "../../components/Banner/BannerTop";
import { SiPypy } from "react-icons/si";
import { RiUser3Line } from "react-icons/ri";
import UserInfo from "../../components/user-info/UserInfo";
import data from "../../constants/data";
import {
  AppPacks,
  ClientTypes,
  OptionType,
  UserTypes,
} from "../../util/context";
import CrownIcon from "../../assets/CrownIcon";
import { colors } from "../../constants";
import IconPlusYellow from "../../assets/images/svg/puls-icon-yellow.svg";
import Select, { components } from "react-select";
import FileViewer from "../../components/FileViewer";
import CreateFacture, {
  GeneralFormValues,
} from "./CreateFacture/CreateFacture";
import { ErrorLogger } from "../../util/errorLogger";
import moment from "moment";
import "moment/locale/fr";
import { toast, ToastContainer } from "react-toastify";
import fileSaver from "file-saver";

import ModelsTabs from "./ModelsTab";
import FactureHtml from "./FactureHtml/FactureHtml";
import LoadFacture from "./LoadFacture/LoadFacture";
import UnderRepair from "../../components/UnderRepair/UnderRepair";
import EditModel from "./EditModel/EditModel";

export interface FactureProps {}
export type TabsType = {
  label: string;
  index: number;
  Component: React.FC;
  type?: string;
  allowedPacks: String[];
}[];

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <img
        src={IconPlusYellow}
        alt="icon"
        className="svg-warning dropdown-custom-icon"
      />
    </components.DropdownIndicator>
  );
};

const ClientTypesArr = [
  { label: "B2B", value: "b2b" },
  { label: "B2C", value: "b2c" },
];

const InvoiceTypes = [
  { label: "Facture", value: "facture" },
  { label: "Devis", value: "devis" },
  { label: "Avoir", value: "avoir" },
];

// Tabs Array
const tabs: TabsType = [
  {
    label: "Liste des factures",
    index: 1,
    Component: ListesFactures,
    type: "facture",
    allowedPacks: [],
  },
  {
    label: "Liste des devis",
    index: 2,
    Component: ListeDevis,
    type: "devis",
    allowedPacks: [],
  },
  {
    label: "Liste des avoirs",
    index: 3,
    Component: ListesAvoirs,
    type: "avoir",
    allowedPacks: [],
  },
];

const Facturation = ({
  client,
  context,
  company,
  callback,
}: {
  client?: User;
  context?: string;
  company?: Company;
  callback?: () => void;
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);
  const [createInvoiceModal, setCreateInvoiceModal] = useState<boolean>(false);
  const [chooseClientTypeModal, setChooseClientTypeModal] =
    useState<boolean>(false);
  const [chooseClientType, setChooseClientType] = useState<OptionType | null>();
  const [fillInvoiceModal, setFillInvoiceModal] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [needEmail, setNeedEmail] = useState<boolean>(false);
  const [needModelRef, setNeedModelRef] = useState<boolean>(false);
  const [formData, setFormData] = useState<{
    rows: GeneralFormValues[];
    companyAddress: string | null;
    companyShareCapital: string | null;
    companyPhone: string | null;
    companySiren: string | null;
    companyTVA: string | null;
    clientName: string | null;
    clientEmail: string | null;
    clientAddress: string | null;
    clientPhone: string | null;
    clientSiren: string | null;
    clientTVA: string | null;
    additionalInfo: string | null;
    legalFooter: string | null;
    invoiceTotalHT: string | null;
    invoiceTotalTVA: string | null;
    invoiceTotalTTC: string | null;
    companyLogo: any;
    paymentLink: string | null;
    receiverEmail: string | null;
    modelRef: string | null;
  }>({
    rows: [],
    companyAddress: "",
    companyShareCapital: "",
    companyPhone: "",
    companySiren: "",
    companyTVA: "",
    clientName: "",
    clientEmail: "",
    clientAddress: "",
    clientPhone: "",
    clientSiren: "",
    clientTVA: "",
    additionalInfo: "",
    legalFooter:
      "Application de l'article L441-6 du Code de commerce et du décret n°2012-1115 du 2 octobre 2012\nPénalités pour retard de paiement : 3 fois le taux de l'intérêt légal + indemnité forfaitaire de recouvrement : 40 € - TVA acquittée sur les encaissements - Aucun escompte ne sera accordé en cas de paiement anticipé.",
    invoiceTotalHT: "",
    invoiceTotalTVA: "",
    invoiceTotalTTC: "",
    companyLogo: "",
    paymentLink: "",
    receiverEmail: "",
    modelRef: "",
  });
  const [invoiceType, setInvoiceType] = useState<{
    value: string;
    label: string;
  } | null>();
  const [modelType, setModelType] = useState<String | null>();

  const [saveAsModelLoading, setSaveAsModelLoading] = useState<boolean>(false);
  const [saveModelLoading, setSaveModelLoading] = useState<boolean>(false);
  const [saveAndSendLoading, setSaveAndSendLoading] = useState<boolean>(false);
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [loaderModelLoading, setLoaderModelLoading] = useState<{
    index: string;
    value: boolean;
  } | null>();

  const [models, setModels] = useState<IModel[]>([]);
  const [modelsType, setModelsType] = useState<OptionType | null>();
  const [modelsViewModal, setModelsViewModal] = useState<boolean>(false);
  const [modelDeleteModal, setModelDeleteModal] = useState<boolean>(false);
  const [modelEditModal, setModelEditModal] = useState<boolean>(false);
  const [createFromModelModal, setCreateFromModelModal] =
    useState<boolean>(false);
  const [singleModel, setSingleModel] = useState<IModel | null>();
  const [isLoaderModel, setIsLoaderModel] = useState<boolean>(false);

  const [singleInvoice, setSingelInvoice] = useState<
    IFacture | IDevis | IAvoir | null
  >();

  const deleteModelWrapper = (modal: boolean, elt: IModel) => {
    setSingleModel(elt);
    setModelDeleteModal(modal);
  };

  const editModelWrapper = (elt: IModel) => {
    setSingleModel(elt);
    setModelsViewModal(false);
    setIsLoaderModel(false);
    setInvoiceType(null);
    setModelsType(null);
    setModelEditModal(true);
  };

  const { id } = useParams();

  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);

  const [invoiceSubCategs, setInvoiceSubCategs] = useState<ISubCategory[]>([]);

  const { user, company: userCompany } = useSelector(
    (state: { root: object; user: object; company: object }) => state.user
  ) as { user: User; company: Company };

  const { newNotif } = useSelector(
    (state: { messagesNotifs: object }) => state.messagesNotifs
  ) as { newNotif: boolean };

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as {
    user_id: string;
    company_id: string;
    token: string;
    role: string;
    pack: string;
  };

  const navigate = useNavigate();
  let api = useAxios();

  const getInvoiceSubCategs = async () => {
    try {
      const { data } = await api.post(
        `/api/SubCategory/all`,
        {
          where: {
            isFacture: "true",
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setInvoiceSubCategs(data.data);
    } catch (error: any) {
      ErrorLogger("getting invoice subCategs", error);
    }
  };

  const itemsCreator = async (
    items: GeneralFormValues[],
    model: string,
    modelId: string
  ) => {
    try {
      let itemsCreated: any = [];
      for (let item of items) {
        const { data: itemCreated } = await api.post(
          `/api/Item/Create`,
          {
            tva: item.tva,
            totalHT: item.totalHT,
            category_id: item.category?.value,
            quantity: item.quantity,
            totalTTC: item.totalTTC,
            totalTVA: item.totalTVA,
            unitPrice: item.unitPrice,
            description: item.description,
            [model]: modelId,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        if (itemCreated.id) {
          itemsCreated.push(itemCreated);
        }
      }
      const ok = items.length === itemsCreated.length;
      if (ok) {
        toast.dismiss();
        // toast.success("Votre modéle a été créé avec succès!", {
        //   position: "bottom-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
      } else {
        toast.dismiss();
        toast.warning(
          "Certains produits/services n'ont pas été créés avec succès",
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      return ok;
    } catch (error: any) {
      ErrorLogger("invoice items creation ", error);
    }
  };

  const itemsEditor = async (items: GeneralFormValues[], modelId: string) => {
    try {
      let itemsCreated: any = [];
      for (let item of items) {
        const { data: itemCreated } = await api.post(
          `/api/Item/Create`,
          {
            tva: item.tva,
            totalHT: item.totalHT,
            category_id: item.category?.value,
            quantity: item.quantity,
            totalTTC: item.totalTTC,
            totalTVA: item.totalTVA,
            unitPrice: item.unitPrice,
            description: item.description,
            modelId: modelId,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        if (itemCreated.id) {
          itemsCreated.push(itemCreated);
        }
      }
      const ok = items.length === itemsCreated.length;
      if (ok) {
        toast.dismiss();
      } else {
        toast.dismiss();
        toast.warning(
          "Certains produits/services n'ont pas été créés avec succès",
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      return ok;
    } catch (error: any) {
      ErrorLogger("invoice items creation ", error);
    }
  };

  const getInvoiceModels = async () => {
    try {
      const { data } = await api.post(
        `/api/InvoiceModel/all`,
        {
          where: {
            name: {
              not: null,
            },
            company_id: creds.company_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setModels(data.data);
    } catch (error: any) {
      ErrorLogger("getting invoice subCategs", error);
    }
  };

  const loadFromModel = async (model: IModel) => {
    try {
      setLoaderModelLoading({
        index: model.id,
        value: true,
      });
      const { data } = await api.post(
        `/api/invoice/loadFromModel`,
        {
          modelId: model.id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (!data.invoice) {
        toast.dismiss();
        toast.warning(
          `Nous ne pouvons pas créer votre ${invoiceType?.value} à partir de ce modèle pour le moment, veuillez réessayer plus tard.`,
          {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setModelsViewModal(false);
        setModelsType(null);
        setInvoiceType(null);
        setIsLoaderModel(false);
        setLoaderModelLoading(null);
        return;
      }

      setSingelInvoice(data.invoice);
      setModelsViewModal(false);
      setIsLoaderModel(false);
      setLoaderModelLoading(null);
      setCreateFromModelModal(true);
    } catch (error: any) {
      ErrorLogger("getting invoice subCategs", error);
      setLoaderModelLoading(null);
    }
  };

  const deleteInvoiceModel = async (id: string) => {
    try {
      await api.post(
        `/api/InvoiceModel/Delete`,
        {
          id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      await getInvoiceModels();
      setModelDeleteModal(false);
    } catch (error: any) {
      ErrorLogger("getting factures data", error);
    }
  };

  const tabRedirect = (tabValue: string = "") => {
    const tab = tabValue === "" ? invoiceType?.value : tabValue;
    if (tabs.findIndex((elt) => elt.type === tab) > -1) {
      const index = tabs.find((elt) => elt.type === tab)?.index || 1;
      if (index !== selectedTab) {
        toast.dismiss();
        toast.info(
          `vous allez être redirigé vers l'onglet ${
            tab === "facture" ? "Facture" : tab === "avoir" ? "Avoir" : "Devis"
          }`,
          {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      setSelectedTab(index);
      setReload(true);
    }
  };

  const saveAsModel = async (used: boolean = false, relatedTo: string = "") => {
    try {
      if (formData.rows.length === 0) {
        toast.dismiss();
        toast.warning(
          `Vous devez ajouter au moins un produit/service à votre ${invoiceType?.value}`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }

      if (!needModelRef || !formData.modelRef || formData.modelRef === "") {
        toast.dismiss();
        toast.warning(`Vous devez ajouter un nom de modèle`, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNeedModelRef(true);
        return;
      }

      if (!used) {
        setSaveAsModelLoading(true);
      }

      let companyLogoURL = "";
      if (formData.companyLogo) {
        const fileData = new FormData();

        fileData.append("file", formData.companyLogo);

        const { data } = await api.post(`/api/tests/uplaodFile`, fileData, {
          headers: {
            "x-access-token": creds.token,
          },
        });

        if (data.urls && data.urls.upload === 1 && data.urls.urls.length > 0) {
          companyLogoURL = data.urls.urls[0].url;
        }
      }
      let payload: any = {
        name: invoiceType?.label,
        modelInfo: {
          companyPhone:
            formData.companyPhone && formData.companyPhone !== ""
              ? formData.companyPhone
              : null,
          companyAddress:
            formData.companyAddress && formData.companyAddress !== ""
              ? formData.companyAddress
              : null,
          paymentLink:
            formData.paymentLink && formData.paymentLink !== ""
              ? formData.paymentLink
              : null,
          additionalInfo:
            formData.additionalInfo === "" ? null : formData.additionalInfo,

          logo: companyLogoURL !== "" ? companyLogoURL : null,
        },
        clientInfo: {
          clientTVA: formData.clientTVA,
          clientTel: formData.clientPhone,
          clientName: formData.clientName,
          clientEmail:
            formData.receiverEmail && formData.receiverEmail !== ""
              ? formData.receiverEmail.trim()
              : null,
          clientSiren: formData.clientSiren,
          clientAdress: formData.clientAddress,
        },
        date: moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        totalTVA: formData.invoiceTotalTVA,
        totalTTC: formData.invoiceTotalTTC,
        totalHT: formData.invoiceTotalHT,
        type: invoiceType?.value,
        status: "102",
        legalFooter: formData.legalFooter === "" ? null : formData.legalFooter,
        company_id: creds.company_id,
        sub_category_id:
          formData.rows.length > 0 ? formData.rows[0].category?.value : null,
        clientType: chooseClientType?.value,
        ref:
          formData.modelRef && formData.modelRef !== ""
            ? formData.modelRef
            : null,
      };
      if (relatedTo !== "") {
        payload.relatedTo = relatedTo;
      }
      const { data } = await api.post(`/api/InvoiceModel/Create`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      if (data.id) {
        const isModelCreated = await itemsCreator(
          formData.rows,
          `model_id`,
          data.id
        );
        if (isModelCreated) {
          toast.dismiss();
          toast.success("Votre modéle a été créé avec succès!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.dismiss();
          toast.warning("Votre modéle n'a pas été créé!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.dismiss();
        toast.warning("Votre modéle n'a pas été créé!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      setSaveAsModelLoading(false);
      if (!used) {
        await getInvoiceModels();
        tabRedirect();
        setTimeout(function () {
          setFillInvoiceModal(false);
          setNeedModelRef(false);
          setInvoiceType(null);
          setChooseClientType(null);
        }, 3000);
      }
    } catch (error: any) {
      ErrorLogger("saving model", error);
      setSaveAsModelLoading(false);
      toast.dismiss();
      toast.warning("Votre modéle n'a pas été créé!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const load_saveAsModel = async (
    used: boolean = false,
    relatedTo: string = ""
  ) => {
    try {
      if (formData.rows.length === 0) {
        toast.dismiss();
        toast.warning(
          `Vous devez ajouter au moins un produit/service ${
            singleInvoice?.name
              ? singleInvoice?.name.toLowerCase() + "à votre"
              : ""
          }`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }

      if (!needModelRef || !formData.modelRef || formData.modelRef === "") {
        toast.dismiss();
        toast.warning(`Vous devez ajouter un nom de modèle`, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setNeedModelRef(true);
        return;
      }

      if (!used) {
        setSaveAsModelLoading(true);
      }

      let payload: any = {
        name: singleInvoice?.name,
        modelInfo: {
          companyPhone:
            formData.companyPhone && formData.companyPhone !== ""
              ? formData.companyPhone
              : null,
          companyAddress:
            formData.companyAddress && formData.companyAddress !== ""
              ? formData.companyAddress
              : null,
          paymentLink:
            formData.paymentLink && formData.paymentLink !== ""
              ? formData.paymentLink
              : null,
          additionalInfo:
            formData.additionalInfo === "" ? null : formData.additionalInfo,
        },
        clientInfo: {
          clientTVA: formData.clientTVA,
          clientTel: formData.clientPhone,
          clientName: formData.clientName,
          clientEmail:
            formData.receiverEmail && formData.receiverEmail !== ""
              ? formData.receiverEmail.trim()
              : null,
          clientSiren: formData.clientSiren,
          clientAdress: formData.clientAddress,
        },
        date: moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        totalTVA: formData.invoiceTotalTVA,
        totalTTC: formData.invoiceTotalTTC,
        totalHT: formData.invoiceTotalHT,
        type: singleInvoice?.name.toLowerCase(),
        status: `${singleInvoice?.status}`,
        legalFooter: formData.legalFooter === "" ? null : formData.legalFooter,
        company_id: creds.company_id,
        sub_category_id:
          formData.rows.length > 0 ? formData.rows[0].category?.value : null,
        clientType: singleInvoice?.clientType,
        ref:
          formData.modelRef && formData.modelRef !== ""
            ? formData.modelRef
            : null,
      };

      if (formData.companyLogo) {
        if (typeof formData.companyLogo !== "string") {
          const fileData = new FormData();

          fileData.append("file", formData.companyLogo);

          const { data } = await api.post(`/api/tests/uplaodFile`, fileData, {
            headers: {
              "x-access-token": creds.token,
            },
          });

          if (
            data.urls &&
            data.urls.upload === 1 &&
            data.urls.urls.length > 0
          ) {
            payload.modelInfo.logo = data.urls.urls[0].url;
          }
        } else {
          payload.modelInfo.logo = formData.companyLogo;
        }
      }

      if (relatedTo !== "") {
        payload.relatedTo = relatedTo;
      }
      const { data } = await api.post(`/api/InvoiceModel/Create`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      if (data.id) {
        const isModelCreated = await itemsCreator(
          formData.rows,
          `model_id`,
          data.id
        );
        if (isModelCreated) {
          toast.dismiss();
          toast.success("Votre modéle a été créé avec succès!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.dismiss();
          toast.warning("Votre modéle n'a pas été créé!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.dismiss();
        toast.warning("Votre modéle n'a pas été créé!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      setSaveAsModelLoading(false);

      if (!used) {
        await getInvoiceModels();
        tabRedirect(singleInvoice?.name.toLowerCase());
        setTimeout(function () {
          setCreateFromModelModal(false);
          setInvoiceType(null);
          setChooseClientType(null);
          setSingelInvoice(null);
          setNeedModelRef(false);
        }, 3000);
      }
    } catch (error: any) {
      ErrorLogger("saving model", error);
      setSaveAsModelLoading(false);
      toast.dismiss();
      toast.warning("Votre modéle n'a pas été créé!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const saveAndSend = async (used: boolean = false) => {
    try {
      if (formData.rows.length === 0) {
        toast.dismiss();
        toast.warning(
          `Vous devez ajouter au moins un produit/service à votre ${invoiceType?.value}`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
      setNeedEmail(false);

      if (!formData.receiverEmail || formData.receiverEmail === "") {
        setNeedEmail(true);
        return;
      }

      if (!used) {
        if (!needModelRef || !formData.modelRef || formData.modelRef === "") {
          toast.dismiss();
          toast.warning(`Vous devez ajouter un nom de modèle`, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setNeedModelRef(true);
          return;
        }
        setSaveAndSendLoading(true);
      }

      let companyLogoURL = "";
      if (formData.companyLogo) {
        const fileData = new FormData();

        fileData.append("file", formData.companyLogo);

        const { data } = await api.post(`/api/tests/uplaodFile`, fileData, {
          headers: {
            "x-access-token": creds.token,
          },
        });

        if (data.urls && data.urls.upload === 1 && data.urls.urls.length > 0) {
          companyLogoURL = data.urls.urls[0].url;
        }
      }
      let payload: any = {
        name: invoiceType?.label,
        date: moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        totalTVA: formData.invoiceTotalTVA,
        totalTTC: formData.invoiceTotalTTC,
        totalHT: formData.invoiceTotalHT,
        type: invoiceType?.value,
        company_id: creds.company_id,
        clientInfo: {
          clientTVA: formData.clientTVA,
          clientTel: formData.clientPhone,
          clientName: formData.clientName,
          clientEmail:
            formData.receiverEmail && formData.receiverEmail !== ""
              ? formData.receiverEmail.trim()
              : null,
          clientSiren: formData.clientSiren,
          clientAdress: formData.clientAddress,
        },
        status: "102",
        sub_category_id:
          formData.rows.length > 0 ? formData.rows[0].category?.value : null,
        clientType: chooseClientType?.value,
      };

      if (invoiceType?.value === "facture") {
        payload.billInfo = {
          companyPhone:
            formData.companyPhone && formData.companyPhone !== ""
              ? formData.companyPhone
              : null,
          companyAddress:
            formData.companyAddress && formData.companyAddress !== ""
              ? formData.companyAddress
              : null,
          paymentLink:
            formData.paymentLink && formData.paymentLink !== ""
              ? formData.paymentLink
              : null,
          additionalInfo:
            formData.additionalInfo === "" ? null : formData.additionalInfo,
          logo: companyLogoURL !== "" ? companyLogoURL : null,
        };
        payload.legalFooter =
          formData.legalFooter === "" ? null : formData.legalFooter;
      } else if (invoiceType?.value === "avoir") {
        payload.avoirInfo = {
          companyPhone:
            formData.companyPhone && formData.companyPhone !== ""
              ? formData.companyPhone
              : null,
          companyAddress:
            formData.companyAddress && formData.companyAddress !== ""
              ? formData.companyAddress
              : null,
          paymentLink:
            formData.paymentLink && formData.paymentLink !== ""
              ? formData.paymentLink
              : null,
          additionalInfo:
            formData.additionalInfo === "" ? null : formData.additionalInfo,
          logo: companyLogoURL !== "" ? companyLogoURL : null,
        };
      } else {
        payload.devisInfo = {
          companyPhone:
            formData.companyPhone && formData.companyPhone !== ""
              ? formData.companyPhone
              : null,
          companyAddress:
            formData.companyAddress && formData.companyAddress !== ""
              ? formData.companyAddress
              : null,
          paymentLink:
            formData.paymentLink && formData.paymentLink !== ""
              ? formData.paymentLink
              : null,
          additionalInfo:
            formData.additionalInfo === "" ? null : formData.additionalInfo,
          logo: companyLogoURL !== "" ? companyLogoURL : null,
        };
      }

      const { data } = await api.post(
        `/api/${invoiceType?.label}/Create`,
        payload,
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (!data.id) {
        toast.dismiss();
        toast.warning(
          `Votre ${
            invoiceType && invoiceType.value === "facture"
              ? "facture n'a pas été créée!"
              : invoiceType?.value === "avoir"
              ? "avoir n'a pas été créé!"
              : "devis n'a pas été créé!"
          }
        `,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setSaveAndSendLoading(false);
        return;
      } else {
        const areItemsCreated = await itemsCreator(
          formData.rows,
          `${invoiceType?.value}_id`,
          data.id
        );
        if (areItemsCreated) {
          const { data: createinvoicePdf } = await api.post(
            `/api/invoice/createInvoicePdf`,
            {
              invoiceModel: invoiceType?.label,
              invoiceId: data.id,
            },
            {
              headers: {
                "x-access-token": creds.token,
              },
            }
          );
          if (createinvoicePdf) {
            if (!used) {
              await saveAsModel(true, data.id);
            }
            const { data: sendEmailData } = await api.post(
              `/api/invoice/sendBillEmail`,
              {
                invoiceModel: invoiceType?.label,
                invoiceId: data.id,
                paymentLink:
                  formData.paymentLink && formData.paymentLink !== ""
                    ? formData.paymentLink
                    : null,
                isReciept: false,
              },
              {
                headers: {
                  "x-access-token": creds.token,
                },
              }
            );

            if (sendEmailData.send) {
              toast.dismiss();
              toast.success(
                `Votre ${
                  invoiceType && invoiceType.value === "facture"
                    ? "facture a été envoyée!"
                    : invoiceType?.value === "avoir"
                    ? "avoir a été envoyé!"
                    : "devis a été envoyé!"
                }`,
                {
                  position: "bottom-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            } else {
              toast.dismiss();
              toast.warning(
                `Votre ${
                  invoiceType && invoiceType.value === "facture"
                    ? "facture n'a pas été envoyée!"
                    : invoiceType?.value === "avoir"
                    ? "avoir n'a pas été envoyé!"
                    : "devis n'a pas été envoyé!"
                }`,
                {
                  position: "bottom-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            }
          }
        }
      }

      setSaveAndSendLoading(false);
      if (!used) {
        await getInvoiceModels();
        tabRedirect();
        setTimeout(function () {
          setFillInvoiceModal(false);
          setInvoiceType(null);
          setChooseClientType(null);
          setNeedModelRef(false);
        }, 3000);
      }
    } catch (error: any) {
      ErrorLogger("saving and sending model", error);
      setSaveAndSendLoading(false);
      toast.dismiss();
      toast.warning(
        `On peut pas traiter votre ${
          invoiceType && invoiceType.value === "facture"
            ? "facture en ce moment!"
            : invoiceType?.value === "avoir"
            ? "avoir en ce moment!"
            : "devis en ce moment!"
        }`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const load_saveAndSend = async (used: boolean = false) => {
    try {
      if (formData.rows.length === 0) {
        toast.dismiss();
        toast.warning(
          `Vous devez ajouter au moins un produit/service ${
            singleInvoice?.name
              ? singleInvoice?.name.toLowerCase() + "à votre"
              : ""
          }`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
      setNeedEmail(false);

      if (!formData.receiverEmail || formData.receiverEmail === "") {
        setNeedEmail(true);
        return;
      }

      if (!used) {
        if (!needModelRef || !formData.modelRef || formData.modelRef === "") {
          toast.dismiss();
          toast.warning(`Vous devez ajouter un nom de modèle`, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setNeedModelRef(true);
          return;
        }
        setSaveAndSendLoading(true);
      }

      let payload: any = {
        name: singleInvoice?.name,
        date: moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        totalTVA: formData.invoiceTotalTVA,
        totalTTC: formData.invoiceTotalTTC,
        totalHT: formData.invoiceTotalHT,
        type: singleInvoice?.name.toLowerCase(),
        company_id: creds.company_id,
        clientInfo: {
          clientTVA: formData.clientTVA,
          clientTel: formData.clientPhone,
          clientName: formData.clientName,
          clientEmail:
            formData.receiverEmail && formData.receiverEmail !== ""
              ? formData.receiverEmail.trim()
              : null,
          clientSiren: formData.clientSiren,
          clientAdress: formData.clientAddress,
        },
        status: `${singleInvoice?.status}`,
        sub_category_id:
          formData.rows.length > 0 ? formData.rows[0].category?.value : null,
        clientType: singleInvoice?.clientType,
        ref:
          formData.modelRef && formData.modelRef !== ""
            ? formData.modelRef
            : null,
      };

      if (singleInvoice?.name.toLowerCase() === "facture") {
        payload.billInfo = {
          companyPhone:
            formData.companyPhone && formData.companyPhone !== ""
              ? formData.companyPhone
              : null,
          companyAddress:
            formData.companyAddress && formData.companyAddress !== ""
              ? formData.companyAddress
              : null,
          paymentLink:
            formData.paymentLink && formData.paymentLink !== ""
              ? formData.paymentLink
              : null,
          additionalInfo:
            formData.additionalInfo === "" ? null : formData.additionalInfo,
        };
        payload.legalFooter =
          formData.legalFooter === "" ? null : formData.legalFooter;
      } else if (singleInvoice?.name.toLowerCase() === "avoir") {
        payload.avoirInfo = {
          companyPhone:
            formData.companyPhone && formData.companyPhone !== ""
              ? formData.companyPhone
              : null,
          companyAddress:
            formData.companyAddress && formData.companyAddress !== ""
              ? formData.companyAddress
              : null,
          paymentLink:
            formData.paymentLink && formData.paymentLink !== ""
              ? formData.paymentLink
              : null,
          additionalInfo:
            formData.additionalInfo === "" ? null : formData.additionalInfo,
        };
      } else {
        payload.devisInfo = {
          companyPhone:
            formData.companyPhone && formData.companyPhone !== ""
              ? formData.companyPhone
              : null,
          companyAddress:
            formData.companyAddress && formData.companyAddress !== ""
              ? formData.companyAddress
              : null,
          paymentLink:
            formData.paymentLink && formData.paymentLink !== ""
              ? formData.paymentLink
              : null,
          additionalInfo:
            formData.additionalInfo === "" ? null : formData.additionalInfo,
        };
      }

      if (formData.companyLogo) {
        if (typeof formData.companyLogo !== "string") {
          const fileData = new FormData();

          fileData.append("file", formData.companyLogo);

          const { data } = await api.post(`/api/tests/uplaodFile`, fileData, {
            headers: {
              "x-access-token": creds.token,
            },
          });

          if (
            data.urls &&
            data.urls.upload === 1 &&
            data.urls.urls.length > 0
          ) {
            if (singleInvoice?.name.toLowerCase() === "facture") {
              payload.billInfo.logo = data.urls.urls[0].url;
            } else if (singleInvoice?.name.toLowerCase() === "avoir") {
              payload.avoirInfo.logo = data.urls.urls[0].url;
            } else {
              payload.devisInfo.logo = data.urls.urls[0].url;
            }
          }
        } else {
          if (singleInvoice?.name.toLowerCase() === "facture") {
            payload.billInfo.logo = formData.companyLogo;
          } else if (singleInvoice?.name.toLowerCase() === "avoir") {
            payload.avoirInfo.logo = formData.companyLogo;
          } else {
            payload.devisInfo.logo = formData.companyLogo;
          }
        }
      }

      payload.id = singleInvoice?.id;

      const { data } = await api.post(
        `/api/${singleInvoice?.name}/Create`,
        payload,
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (!data.id) {
        toast.dismiss();
        toast.warning(
          `Votre ${
            singleInvoice?.name && singleInvoice?.name === "facture"
              ? "facture n'a pas été modifiée!"
              : singleInvoice?.name === "avoir"
              ? "avoir n'a pas été modifié!"
              : "devis n'a pas été modifié!"
          }
        `,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setSaveAndSendLoading(false);
        return;
      } else {
        const areItemsCreated = await itemsCreator(
          formData.rows,
          `${singleInvoice?.name.toLowerCase()}_id`,
          data.id
        );
        if (areItemsCreated) {
          if (!used) {
            await load_saveAsModel(true, singleInvoice?.id);
          }
          const { data: createInvoicePdf } = await api.post(
            `/api/invoice/createInvoicePdf`,
            {
              invoiceModel: singleInvoice?.name,
              invoiceId: singleInvoice?.id,
            },
            {
              headers: {
                "x-access-token": creds.token,
              },
            }
          );

          if (createInvoicePdf) {
            const { data: sendEmailData } = await api.post(
              `/api/invoice/sendBillEmail`,
              {
                invoiceModel: singleInvoice?.name,
                invoiceId: singleInvoice?.id,
                paymentLink:
                  formData.paymentLink && formData.paymentLink !== ""
                    ? formData.paymentLink
                    : null,
                isReciept: false,
              },
              {
                headers: {
                  "x-access-token": creds.token,
                },
              }
            );

            if (sendEmailData.send) {
              toast.dismiss();
              toast.success(
                `Votre ${
                  singleInvoice?.name.toLowerCase() &&
                  singleInvoice?.name.toLowerCase() === "facture"
                    ? "facture a été envoyée!"
                    : singleInvoice?.name.toLowerCase() === "avoir"
                    ? "avoir a été envoyé!"
                    : "devis a été envoyé!"
                }`,
                {
                  position: "bottom-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            } else {
              toast.dismiss();
              toast.warning(
                `Votre ${
                  singleInvoice?.name.toLowerCase() &&
                  singleInvoice?.name.toLowerCase() === "facture"
                    ? "facture n'a pas été envoyée!"
                    : singleInvoice?.name.toLowerCase() === "avoir"
                    ? "avoir n'a pas été envoyé!"
                    : "devis n'a pas été envoyé!"
                }`,
                {
                  position: "bottom-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
            }
          }
        }
      }

      setSaveAndSendLoading(false);
      if (!used) {
        await getInvoiceModels();
        tabRedirect(singleInvoice?.name.toLowerCase());
        setTimeout(function () {
          setCreateFromModelModal(false);
          setInvoiceType(null);
          setChooseClientType(null);
          setSingelInvoice(null);
          setNeedModelRef(false);
        }, 3000);
      }
    } catch (error: any) {
      ErrorLogger("saving and sending model", error);
      setSaveAndSendLoading(false);
      toast.dismiss();
      toast.warning(
        `On peut pas traiter votre ${
          singleInvoice?.name.toLowerCase() &&
          singleInvoice?.name.toLowerCase() === "facture"
            ? "facture en ce moment!"
            : singleInvoice?.name.toLowerCase() === "avoir"
            ? "avoir en ce moment!"
            : "devis en ce moment!"
        }`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const send = async () => {
    try {
      if (formData.rows.length === 0) {
        toast.dismiss();
        toast.warning(
          `Vous devez ajouter au moins un produit/service à votre ${invoiceType?.value}`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
      setNeedEmail(false);

      if (!formData.receiverEmail || formData.receiverEmail === "") {
        setNeedEmail(true);
        return;
      }
      setSendLoading(true);
      await saveAndSend(true);
      setSendLoading(false);
      tabRedirect();
      setTimeout(function () {
        setFillInvoiceModal(false);
        setInvoiceType(null);
        setChooseClientType(null);
      }, 3000);

      // setReload(true);
    } catch (error: any) {
      ErrorLogger("saving and sending model", error);
      setSendLoading(false);
      toast.dismiss();
      toast.warning(
        `On peut pas traiter votre ${
          invoiceType && invoiceType.value === "facture"
            ? "facture en ce moment!"
            : invoiceType?.value === "avoir"
            ? "avoir en ce moment!"
            : "devis en ce moment!"
        }`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const saveModel = async () => {
    try {
      if (formData.rows.length === 0) {
        toast.dismiss();
        toast.warning(
          `Vous devez ajouter au moins un produit/service à votre modéle`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
      setNeedEmail(false);

      // if (!formData.receiverEmail || formData.receiverEmail === "") {
      //   setNeedEmail(true);
      //   return;
      // }
      setSaveModelLoading(true);

      let companyLogoURL = formData.companyLogo;
      if (formData.companyLogo && typeof formData.companyLogo !== "string") {
        const fileData = new FormData();

        fileData.append("file", formData.companyLogo);

        const { data } = await api.post(`/api/tests/uplaodFile`, fileData, {
          headers: {
            "x-access-token": creds.token,
          },
        });

        if (data.urls && data.urls.upload === 1 && data.urls.urls.length > 0) {
          companyLogoURL = data.urls.urls[0].url;
        }
      }
      let payload: any = {
        id: singleModel?.id,
        modelInfo: {
          ...singleModel?.modelInfo,
          paymentLink:
            formData.paymentLink && formData.paymentLink !== ""
              ? formData.paymentLink
              : null,
          additionalInfo:
            formData.additionalInfo === "" ? null : formData.additionalInfo,
          logo: companyLogoURL !== "" ? companyLogoURL : null,
          companyPhone:
            formData.companyPhone && formData.companyPhone !== ""
              ? formData.companyPhone
              : null,
          companyAddress:
            formData.companyAddress && formData.companyAddress !== ""
              ? formData.companyAddress
              : null,
        },
        clientInfo: {
          clientTVA: formData.clientTVA,
          clientTel: formData.clientPhone,
          clientName: formData.clientName,
          clientEmail:
            formData.receiverEmail && formData.receiverEmail !== ""
              ? formData.receiverEmail.trim()
              : null,
          clientSiren: formData.clientSiren,
          clientAdress: formData.clientAddress,
        },
        totalTVA: formData.invoiceTotalTVA,
        totalTTC: formData.invoiceTotalTTC,
        totalHT: formData.invoiceTotalHT,
        legalFooter: formData.legalFooter === "" ? null : formData.legalFooter,
        sub_category_id:
          formData.rows.length > 0 ? formData.rows[0].category?.value : null,
        ref:
          formData.modelRef && formData.modelRef !== ""
            ? formData.modelRef
            : null,
      };
      const { data } = await api.post(`/api/InvoiceModel/Update`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      if (
        data.updated &&
        data.updated.length > 0 &&
        data.updated[0].view &&
        data.updated[0].view.id
      ) {
        const isModelEdited = await itemsEditor(
          formData.rows.filter((elt) => typeof elt.id !== "string"),
          singleModel?.id!
        );
        if (isModelEdited) {
          toast.dismiss();
          toast.success("Votre modèle a été mis à jour avec succès!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.dismiss();
          toast.warning("Votre modéle n'a pas été mis à jour!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } else {
        toast.dismiss();
        toast.warning("Votre modéle n'a pas été mis à jour!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      setSaveModelLoading(false);
      setTimeout(function () {
        setModelEditModal(false);
        setSaveModelLoading(false);
        setNeedEmail(false);
        setSingelInvoice(null);
      }, 3000);

      await getInvoiceModels();
    } catch (error: any) {
      ErrorLogger("saving model", error);
      setSaveModelLoading(false);
      toast.dismiss();
      toast.warning(`On peut pas traiter votre modéle en ce moment!`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const load_send = async () => {
    try {
      if (formData.rows.length === 0) {
        toast.dismiss();
        toast.warning(
          `Vous devez ajouter au moins un produit/service ${
            singleInvoice?.name
              ? singleInvoice?.name.toLowerCase() + "à votre"
              : ""
          }`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
      setNeedEmail(false);

      if (!formData.receiverEmail || formData.receiverEmail === "") {
        setNeedEmail(true);
        return;
      }
      setSendLoading(true);
      await load_saveAndSend(true);
      setSendLoading(false);
      tabRedirect(singleInvoice?.name.toLowerCase());
      setTimeout(function () {
        setCreateFromModelModal(false);
        setInvoiceType(null);
        setChooseClientType(null);
        setSingelInvoice(null);
      }, 3000);

      // setReload(true);
    } catch (error: any) {
      ErrorLogger("saving and sending model", error);
      setSendLoading(false);
      toast.dismiss();
      toast.warning(
        `On peut pas traiter votre ${
          singleInvoice?.name.toLowerCase() &&
          singleInvoice?.name.toLowerCase() === "facture"
            ? "facture en ce moment!"
            : singleInvoice?.name.toLowerCase() === "avoir"
            ? "avoir en ce moment!"
            : "devis en ce moment!"
        }`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const download = async () => {
    try {
      console.log("formData : ", formData);
      if (formData.rows.length === 0) {
        toast.dismiss();
        toast.warning(
          `Vous devez ajouter au moins un produit/service à votre ${invoiceType?.value}`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
      setDownloadLoading(true);

      let companyLogoURL = "";
      if (formData.companyLogo) {
        const fileData = new FormData();

        fileData.append("file", formData.companyLogo);

        const { data } = await api.post(`/api/tests/uplaodFile`, fileData, {
          headers: {
            "x-access-token": creds.token,
          },
        });

        if (data.urls && data.urls.upload === 1 && data.urls.urls.length > 0) {
          companyLogoURL = data.urls.urls[0].url;
        }
      }
      let payload: any = {
        name: invoiceType?.label,
        date: moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        totalTVA: formData.invoiceTotalTVA,
        totalTTC: formData.invoiceTotalTTC,
        totalHT: formData.invoiceTotalHT,
        type: invoiceType?.value,
        company_id: creds.company_id,
        clientInfo: {
          clientTVA: formData.clientTVA,
          clientTel: formData.clientPhone,
          clientName: formData.clientName,
          clientEmail:
            formData.receiverEmail && formData.receiverEmail !== ""
              ? formData.receiverEmail.trim()
              : null,
          clientSiren: formData.clientSiren,
          clientAdress: formData.clientAddress,
        },
        status: "102",
        sub_category_id:
          formData.rows.length > 0 ? formData.rows[0].category?.value : null,
        clientType: chooseClientType?.value,
      };

      if (invoiceType?.value === "facture") {
        payload.billInfo = {
          companyPhone:
            formData.companyPhone && formData.companyPhone !== ""
              ? formData.companyPhone
              : null,
          companyAddress:
            formData.companyAddress && formData.companyAddress !== ""
              ? formData.companyAddress
              : null,
          paymentLink:
            formData.paymentLink && formData.paymentLink !== ""
              ? formData.paymentLink
              : null,
          additionalInfo:
            formData.additionalInfo === "" ? null : formData.additionalInfo,
          logo: companyLogoURL !== "" ? companyLogoURL : null,
        };
        payload.legalFooter =
          formData.legalFooter === "" ? null : formData.legalFooter;
      } else if (invoiceType?.value === "avoir") {
        payload.avoirInfo = {
          companyPhone:
            formData.companyPhone && formData.companyPhone !== ""
              ? formData.companyPhone
              : null,
          companyAddress:
            formData.companyAddress && formData.companyAddress !== ""
              ? formData.companyAddress
              : null,
          paymentLink:
            formData.paymentLink && formData.paymentLink !== ""
              ? formData.paymentLink
              : null,
          additionalInfo:
            formData.additionalInfo === "" ? null : formData.additionalInfo,
          logo: companyLogoURL !== "" ? companyLogoURL : null,
        };
      } else {
        payload.devisInfo = {
          companyPhone:
            formData.companyPhone && formData.companyPhone !== ""
              ? formData.companyPhone
              : null,
          companyAddress:
            formData.companyAddress && formData.companyAddress !== ""
              ? formData.companyAddress
              : null,
          paymentLink:
            formData.paymentLink && formData.paymentLink !== ""
              ? formData.paymentLink
              : null,
          additionalInfo:
            formData.additionalInfo === "" ? null : formData.additionalInfo,
          logo: companyLogoURL !== "" ? companyLogoURL : null,
        };
      }

      const { data } = await api.post(
        `/api/${invoiceType?.label}/Create`,
        payload,
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (!data.id) {
        toast.dismiss();
        toast.warning(
          `Votre ${
            invoiceType && invoiceType.value === "facture"
              ? "facture n'a pas été créée!"
              : invoiceType?.value === "avoir"
              ? "avoir n'a pas été créé!"
              : "devis n'a pas été créé!"
          }
        `,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setSaveAndSendLoading(false);
        return;
      } else {
        const areItemsCreated = await itemsCreator(
          formData.rows,
          `${invoiceType?.value.toLowerCase()}_id`,
          data.id
        );
        if (areItemsCreated) {
          const { data: createinvoicePdf } = await api.post(
            `/api/invoice/createInvoicePdf`,
            {
              invoiceModel: invoiceType?.label,
              invoiceId: data.id,
            },
            {
              headers: {
                "x-access-token": creds.token,
              },
            }
          );
          if (createinvoicePdf) {
            const { data: invoiceCreated } = await api.post(
              `/api/${invoiceType?.label}`,
              {
                id: data.id,
              },
              {
                headers: {
                  "x-access-token": creds.token,
                },
              }
            );

            if (invoiceCreated.id) {
              if (
                invoiceType?.value === "facture" &&
                invoiceCreated.billS3link &&
                invoiceCreated.billS3link.url
              ) {
                await fileSaver(
                  invoiceCreated.billS3link.url,
                  invoiceCreated.billS3link.key
                );
              }
              if (
                invoiceType?.value === "avoir" &&
                invoiceCreated.avoirS3link &&
                invoiceCreated.avoirS3link.url
              ) {
                await fileSaver(
                  invoiceCreated.avoirS3link.url,
                  invoiceCreated.avoirS3link.key
                );
              }
              if (
                invoiceType?.value === "devis" &&
                invoiceCreated.devisS3link &&
                invoiceCreated.devisS3link.url
              ) {
                await fileSaver(
                  invoiceCreated.devisS3link.url,
                  invoiceCreated.devisS3link.key
                );
              }
            } else {
              toast.dismiss();
              toast.warning(
                `Votre ${
                  invoiceType && invoiceType.value === "facture"
                    ? "facture n'a pas pu être traitée pour le moment!"
                    : invoiceType?.value === "avoir"
                    ? "avoir n'a pas pu être traité pour le moment!"
                    : "devis n'a pas pu être traité pour le moment!"
                }
              `,
                {
                  position: "bottom-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
              setSaveAndSendLoading(false);
              return;
            }
          }
        }
      }

      setDownloadLoading(false);
      tabRedirect();
      setTimeout(function () {
        setFillInvoiceModal(false);
        setInvoiceType(null);
        setChooseClientType(null);
      }, 3000);

      // setReload(true);
    } catch (error: any) {
      ErrorLogger("saving and sending model", error);
      setDownloadLoading(false);
      toast.dismiss();
      toast.warning(
        `On peut pas traiter votre ${
          invoiceType && invoiceType.value === "facture"
            ? "facture en ce moment!"
            : invoiceType?.value === "avoir"
            ? "avoir en ce moment!"
            : "devis en ce moment!"
        }`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const load_download = async () => {
    try {
      if (formData.rows.length === 0) {
        toast.dismiss();
        toast.warning(
          `Vous devez ajouter au moins un produit/service ${
            singleInvoice?.name
              ? singleInvoice?.name.toLowerCase() + "à votre"
              : ""
          }`,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      }
      setDownloadLoading(true);

      let payload: any = {
        name: singleInvoice?.name,
        date: moment().set({ hour: 12, minute: 0 }).format("YYYY-MM-DD hh:mm"),
        totalTVA: formData.invoiceTotalTVA,
        totalTTC: formData.invoiceTotalTTC,
        totalHT: formData.invoiceTotalHT,
        type: invoiceType?.value,
        company_id: creds.company_id,
        clientInfo: {
          clientTVA: formData.clientTVA,
          clientTel: formData.clientPhone,
          clientName: formData.clientName,
          clientEmail:
            formData.receiverEmail && formData.receiverEmail !== ""
              ? formData.receiverEmail.trim()
              : null,
          clientSiren: formData.clientSiren,
          clientAdress: formData.clientAddress,
        },
        status: `${singleInvoice?.status}`,
        sub_category_id:
          formData.rows.length > 0 ? formData.rows[0].category?.value : null,
        clientType: singleInvoice?.clientType,
      };

      if (singleInvoice?.name.toLowerCase() === "facture") {
        payload.billInfo = {
          companyPhone:
            formData.companyPhone && formData.companyPhone !== ""
              ? formData.companyPhone
              : null,
          companyAddress:
            formData.companyAddress && formData.companyAddress !== ""
              ? formData.companyAddress
              : null,
          paymentLink:
            formData.paymentLink && formData.paymentLink !== ""
              ? formData.paymentLink
              : null,
          additionalInfo:
            formData.additionalInfo === "" ? null : formData.additionalInfo,
        };
        payload.legalFooter =
          formData.legalFooter === "" ? null : formData.legalFooter;
      } else if (singleInvoice?.name.toLowerCase() === "avoir") {
        payload.avoirInfo = {
          companyPhone:
            formData.companyPhone && formData.companyPhone !== ""
              ? formData.companyPhone
              : null,
          companyAddress:
            formData.companyAddress && formData.companyAddress !== ""
              ? formData.companyAddress
              : null,
          paymentLink:
            formData.paymentLink && formData.paymentLink !== ""
              ? formData.paymentLink
              : null,
          additionalInfo:
            formData.additionalInfo === "" ? null : formData.additionalInfo,
        };
      } else {
        payload.devisInfo = {
          companyPhone:
            formData.companyPhone && formData.companyPhone !== ""
              ? formData.companyPhone
              : null,
          companyAddress:
            formData.companyAddress && formData.companyAddress !== ""
              ? formData.companyAddress
              : null,
          paymentLink:
            formData.paymentLink && formData.paymentLink !== ""
              ? formData.paymentLink
              : null,
          additionalInfo:
            formData.additionalInfo === "" ? null : formData.additionalInfo,
        };
      }

      if (formData.companyLogo) {
        if (typeof formData.companyLogo !== "string") {
          const fileData = new FormData();

          fileData.append("file", formData.companyLogo);

          const { data } = await api.post(`/api/tests/uplaodFile`, fileData, {
            headers: {
              "x-access-token": creds.token,
            },
          });

          if (
            data.urls &&
            data.urls.upload === 1 &&
            data.urls.urls.length > 0
          ) {
            if (singleInvoice?.name.toLowerCase() === "facture") {
              payload.billInfo.logo = data.urls.urls[0].url;
            } else if (singleInvoice?.name.toLowerCase() === "avoir") {
              payload.avoirInfo.logo = data.urls.urls[0].url;
            } else {
              payload.devisInfo.logo = data.urls.urls[0].url;
            }
          }
        } else {
          if (singleInvoice?.name.toLowerCase() === "facture") {
            payload.billInfo.logo = formData.companyLogo;
          } else if (singleInvoice?.name.toLowerCase() === "avoir") {
            payload.avoirInfo.logo = formData.companyLogo;
          } else {
            payload.devisInfo.logo = formData.companyLogo;
          }
        }
      }

      payload.id = singleInvoice?.id;

      const { data } = await api.post(
        `/api/${singleInvoice?.name}/Create`,
        payload,
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (!data.id) {
        toast.dismiss();
        toast.warning(
          `Votre ${
            singleInvoice?.name && singleInvoice?.name === "facture"
              ? "facture n'a pas été modifiée!"
              : singleInvoice?.name === "avoir"
              ? "avoir n'a pas été modifié!"
              : "devis n'a pas été modifié!"
          }
        `,
          {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setSaveAndSendLoading(false);
        return;
      } else {
        const areItemsCreated = await itemsCreator(
          formData.rows,
          `${singleInvoice?.name.toLowerCase()}_id`,
          data.id
        );
        if (areItemsCreated) {
          const { data: createinvoicePdf } = await api.post(
            `/api/invoice/createInvoicePdf`,
            {
              invoiceModel: singleInvoice?.name,
              invoiceId: data.id,
            },
            {
              headers: {
                "x-access-token": creds.token,
              },
            }
          );
          if (createinvoicePdf) {
            const { data: invoiceCreated } = await api.post(
              `/api/${invoiceType?.label}`,
              {
                id: data.id,
              },
              {
                headers: {
                  "x-access-token": creds.token,
                },
              }
            );

            if (invoiceCreated.id) {
              if (
                singleInvoice?.name.toLowerCase() === "facture" &&
                invoiceCreated.billS3link &&
                invoiceCreated.billS3link.url
              ) {
                await fileSaver(
                  invoiceCreated.billS3link.url,
                  invoiceCreated.billS3link.key
                );
              }
              if (
                singleInvoice?.name.toLowerCase() === "avoir" &&
                invoiceCreated.avoirS3link &&
                invoiceCreated.avoirS3link.url
              ) {
                await fileSaver(
                  invoiceCreated.avoirS3link.url,
                  invoiceCreated.avoirS3link.key
                );
              }
              if (
                singleInvoice?.name.toLowerCase() === "devis" &&
                invoiceCreated.devisS3link &&
                invoiceCreated.devisS3link.url
              ) {
                await fileSaver(
                  invoiceCreated.devisS3link.url,
                  invoiceCreated.devisS3link.key
                );
              }
            } else {
              toast.dismiss();
              toast.warning(
                `Votre ${
                  invoiceType && invoiceType.value === "facture"
                    ? "facture n'a pas pu être traitée pour le moment!"
                    : invoiceType?.value === "avoir"
                    ? "avoir n'a pas pu être traité pour le moment!"
                    : "devis n'a pas pu être traité pour le moment!"
                }
              `,
                {
                  position: "bottom-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                }
              );
              setSaveAndSendLoading(false);
              return;
            }
          }
        }
      }

      setDownloadLoading(false);
      tabRedirect(singleInvoice?.name.toLowerCase());
      setTimeout(function () {
        setCreateFromModelModal(false);
        setInvoiceType(null);
        setChooseClientType(null);
        setSingelInvoice(null);
      }, 3000);

      // setReload(true);
    } catch (error: any) {
      ErrorLogger("saving and sending model", error);
      setDownloadLoading(false);
      toast.dismiss();
      toast.warning(
        `On peut pas traiter votre ${
          singleInvoice?.name.toLowerCase() &&
          singleInvoice?.name.toLowerCase() === "facture"
            ? "facture en ce moment!"
            : singleInvoice?.name.toLowerCase() === "avoir"
            ? "avoir en ce moment!"
            : "devis en ce moment!"
        }`,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  const toggleEditModelModal = async () => {
    setModelEditModal(false);
    setSaveModelLoading(false);
    setNeedEmail(false);
    setSingelInvoice(null);
    await getInvoiceModels();
  };

  useEffect(() => {
    getInvoiceSubCategs();
   
  }, []);

  useEffect(() => {
    if (creds.company_id) {
      getInvoiceModels();
    }
  }, [creds.company_id]);

  return (
    <div className="page section-facturation">
      {user.id && user.role === UserTypes.Client ? (
        <>
          <div
            className={`top-content ${
              creds.role === UserTypes.Client ? "client-resize-header" : ""
            }`}
          >
            <Row>
              <Col lg={8} md={12} className="blockBanner">
                <BannerTop banner={data.invoice} />
              </Col>
              <Col lg={4} md={12}>
                <Card className="blockUser">
                  <Button
                    className="btn-icon btn-icon-primary"
                    color="light"
                    onClick={() => navigate("/tickets")}
                  >
                    {newNotif && <Badge color="primary"> </Badge>}
                    <CrownIcon stroke={colors.primaryColor} />
                  </Button>
                  <UserInfo user={user} />
                  <Button
                    className="btn-icon btn-icon-primary"
                    color="light"
                    onClick={() =>
                      navigate({
                        pathname: "/profil/client",
                        // search: "?page=1&tab=1",
                      })
                    }
                  >
                    <RiUser3Line />
                  </Button>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="card-Table table-primary card">
        <>
          {creds.role === UserTypes.Client && (
            <div className="create-indem__button action-top-tabs client-create-invoice-wrapper">
              <FormGroup className="form-icon icon-start">
                <Select
                  options={InvoiceTypes}
                  closeMenuOnSelect={true}
                  placeholder="Mes Modèles"
                  classNamePrefix="select"
                  className="custom-select form-secondary"
                  onChange={(type) => {
                    setModelsType(type);
                    setModelsViewModal(true);
                  }}
                  value={modelsType}
                />
              </FormGroup>

              <FormGroup className="form-icon icon-start">
                <Select
                  options={InvoiceTypes}
                  closeMenuOnSelect={true}
                  placeholder="Créer"
                  classNamePrefix="select"
                  className="custom-select form-secondary custom-facture-select"
                  onChange={(invoice) => {
                    setInvoiceType(invoice);
                    setCreateInvoiceModal(true);
                  }}
                  components={{ DropdownIndicator }}
                  value={invoiceType}
                />
              </FormGroup>
            </div>
          )}
          <Tabs
            selectedTab={selectedTab}
            onClick={setSelectedTab}
            tabs={tabs.filter((elt) => {
              if (elt.allowedPacks.length > 0) {
                return elt.allowedPacks.includes(
                  creds.role === UserTypes.Client ? creds.pack : company?.pack!
                );
              } else return elt;
            })}
            context={context}
            company={company}
            client={client}
            reload={reload}
            callback={setReload}
            reloadParent={getInvoiceModels}
          />
        </>
      </div>
      <div className="openbtn text-center">
        {/*choose whaat kind of invoice*/}
        <Modal
          className="modal-secondary modal-dialog-centered"
          isOpen={createInvoiceModal}
          size={"sm"}
          toggle={() => {
            setCreateInvoiceModal(false);
            setInvoiceType(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setCreateInvoiceModal(false);
              setInvoiceType(null);
            }}
          >
            Créer {invoiceType?.value}
          </ModalHeader>
          <ModalBody>
            <div className="sync-modal">
              <div className="action-button-wrapper">
                <Button
                  color="secondary"
                  outline
                  type="button"
                  onClick={() => {
                    setCreateInvoiceModal(false);
                    setModelsType(
                      InvoiceTypes.find(
                        (elt) => elt.value === invoiceType?.value
                      )
                    );
                    setIsLoaderModel(true);
                    setModelsViewModal(true);
                  }}
                >
                  Modéle déjà créé
                </Button>
              </div>
              <div className="action-button-wrapper">
                <Button
                  color="secondary"
                  outline
                  type="submit"
                  onClick={() => {
                    setCreateInvoiceModal(false);
                    setChooseClientTypeModal(true);
                  }}
                >
                  Nouveau modéle
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*choose reciever type b2b / b2c*/}
        <Modal
          className="modal-secondary modal-dialog-centered"
          isOpen={chooseClientTypeModal}
          size={"sm"}
          toggle={() => {
            setChooseClientTypeModal(false);
            setChooseClientType(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setChooseClientTypeModal(false);
              setChooseClientType(null);
            }}
          >
            Choisissez le type
          </ModalHeader>
          <ModalBody>
            <div className="sync-modal">
              <div className="action-button-wrapper">
                <Button
                  color="secondary"
                  outline
                  type="button"
                  onClick={() => {
                    setChooseClientTypeModal(false);
                    setChooseClientType(
                      ClientTypesArr.find((elt) => elt.value === "b2b")
                    );
                    setFillInvoiceModal(true);
                  }}
                >
                  B2B
                </Button>
              </div>
              <div className="action-button-wrapper">
                <Button
                  color="secondary"
                  outline
                  type="button"
                  onClick={() => {
                    setChooseClientTypeModal(false);
                    setChooseClientType(
                      ClientTypesArr.find((elt) => elt.value === "b2c")
                    );
                    setFillInvoiceModal(true);
                  }}
                >
                  B2C
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/*view models*/}
        <Modal
          className="modal-primary modal-dialog-centered models-view-modal"
          isOpen={modelsViewModal}
          toggle={() => {
            setModelsViewModal(false);
            setIsLoaderModel(false);
            setInvoiceType(null);
            setModelsType(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setModelsViewModal(false);
              setIsLoaderModel(false);
              setInvoiceType(null);
              setModelsType(null);
            }}
          >
            Mes modèles ({modelsType?.label})
          </ModalHeader>
          <ModalBody>
            <div className="models-view-section ">
              {models.filter(
                (elt) => elt.type.toLowerCase() === modelsType?.value
              ).length > 0 ? (
                <ModelsTabs
                  orientation="vertical"
                  tabs={models
                    .filter(
                      (elt) => elt.type.toLowerCase() === modelsType?.value
                    )
                    .map((elt, index) => {
                      return {
                        index,
                        Component: () => (
                          <FactureHtml
                            invoice={elt}
                            client={client}
                            type={"model"}
                          />
                        ),
                        model: elt,
                      };
                    })}
                  context={creds.company_id}
                  callback={!isLoaderModel ? deleteModelWrapper : loadFromModel}
                  isLoaderModel={isLoaderModel}
                  loaderModelLoading={loaderModelLoading}
                  editModelWrapper={editModelWrapper}
                />
              ) : (
                <div className="text-center">
                  <p>Il n'y a pas encore de modèle enregistré</p>
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>
        {/*delete model*/}
        <Modal
          className="modal-danger modal-dialog-centered"
          isOpen={modelDeleteModal}
          toggle={() => {
            setModelDeleteModal(false);
            setSingleModel(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setModelDeleteModal(false);
              setSingleModel(null);
            }}
          >
            Supprmier le modéle {singleModel?.id.toUpperCase()}
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir supprimer le modéle{" "}
                {singleModel?.id.toUpperCase()} ?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              outline
              onClick={() => {
                setModelDeleteModal(false);
                setSingleModel(null);
              }}
            >
              Non
            </Button>
            <Button
              color="danger"
              onClick={async () => await deleteInvoiceModel(singleModel?.id!)}
            >
              Oui
            </Button>
          </ModalFooter>
        </Modal>
        {/*create Asset*/}
        <Modal
          className="modal-primary modal-dialog-centered fill-invoice-modal"
          isOpen={fillInvoiceModal}
          size={"xl"}
          toggle={() => {
            setFillInvoiceModal(false);
            setInvoiceType(null);
            setChooseClientType(null);
            setSaveAsModelLoading(false);
            setSaveAndSendLoading(false);
            setSendLoading(false);
            setDownloadLoading(false);
            setNeedEmail(false);
            setNeedModelRef(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setFillInvoiceModal(false);
              setInvoiceType(null);
              setChooseClientType(null);
              setSaveAsModelLoading(false);
              setSaveAndSendLoading(false);
              setSendLoading(false);
              setDownloadLoading(false);
              setNeedEmail(false);
              setNeedModelRef(false);
            }}
          >
            Créer{" "}
            {invoiceType && invoiceType.value === "facture"
              ? "une facture"
              : invoiceType?.value === "avoir"
              ? "un avoir"
              : "un devis"}{" "}
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5">
              <CreateFacture
                model={invoiceType?.label!}
                invoiceSubCategs={invoiceSubCategs}
                formData={formData}
                setFormData={setFormData}
                needEmail={needEmail}
                setNeedEmail={setNeedEmail}
                clientType={chooseClientType!}
                needModelRef={needModelRef}
                setNeedModelRef={setNeedModelRef}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              outline
              type="button"
              onClick={() => {
                setFillInvoiceModal(false);
                setInvoiceType(null);
                setChooseClientType(null);
                setSaveAsModelLoading(false);
                setSaveAndSendLoading(false);
                setSendLoading(false);
                setDownloadLoading(false);
                setNeedEmail(false);
              }}
            >
              Retour
            </Button>
            <Button
              color="success"
              outline
              type="button"
              onClick={async () => {
                await saveAsModel();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {saveAsModelLoading ? (
                <Spinner color="success" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Enregistrer comme modéle"
              )}
            </Button>
            <Button
              color="primary"
              outline
              type="button"
              onClick={async () => {
                await saveAndSend();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {saveAndSendLoading ? (
                <Spinner color="success" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Enregistrer comme modéle et envoyer"
              )}
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={async () => {
                await send();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {sendLoading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Envoyer"
              )}
            </Button>
            <Button
              color="success"
              type="button"
              onClick={async () => {
                await download();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {downloadLoading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Télécharger"
              )}
            </Button>
          </ModalFooter>
        </Modal>
        {/*create Asset from model*/}
        <Modal
          className="modal-primary modal-dialog-centered fill-invoice-modal"
          isOpen={createFromModelModal}
          size={"xl"}
          toggle={() => {
            setCreateFromModelModal(false);
            setInvoiceType(null);
            setChooseClientType(null);
            setSaveAsModelLoading(false);
            setSaveAndSendLoading(false);
            setSendLoading(false);
            setDownloadLoading(false);
            setNeedEmail(false);
            setNeedModelRef(false);
            setSingelInvoice(null);
            setLoaderModelLoading(null);
            setNeedModelRef(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setCreateFromModelModal(false);
              setInvoiceType(null);
              setChooseClientType(null);
              setSaveAsModelLoading(false);
              setSaveAndSendLoading(false);
              setSendLoading(false);
              setDownloadLoading(false);
              setNeedEmail(false);
              setNeedModelRef(false);
              setSingelInvoice(null);
              setLoaderModelLoading(null);
              setModelsType(null);
            }}
          >
            Créer{" "}
            {invoiceType && invoiceType.value === "facture"
              ? "une facture"
              : invoiceType?.value === "avoir"
              ? "un avoir"
              : "un devis"}{" "}
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5">
              <LoadFacture
                model={singleInvoice?.name!}
                invoiceSubCategs={invoiceSubCategs}
                formData={formData}
                setFormData={setFormData}
                needEmail={needEmail}
                setNeedEmail={setNeedEmail}
                clientType={{
                  label:
                    singleInvoice?.clientType === ClientTypes.B2B
                      ? "B2B"
                      : "B2C",
                  value: singleInvoice?.clientType,
                }}
                invoice={singleInvoice!}
                needModelRef={needModelRef}
                setNeedModelRef={setNeedModelRef}
                invoiceType={
                  invoiceType && invoiceType.value === "facture"
                    ? "Facture"
                    : invoiceType?.value === "avoir"
                    ? "Avoir"
                    : "Devis"
                }
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              outline
              type="button"
              onClick={() => {
                setCreateFromModelModal(false);
                setInvoiceType(null);
                setChooseClientType(null);
                setSaveAsModelLoading(false);
                setSaveAndSendLoading(false);
                setSendLoading(false);
                setDownloadLoading(false);
                setNeedEmail(false);
                setNeedModelRef(false);
                setSingelInvoice(null);
                setLoaderModelLoading(null);
                setModelsType(null);
              }}
            >
              Retour
            </Button>
            <Button
              color="success"
              outline
              type="button"
              onClick={async () => {
                setModelsType(null);
                await load_saveAsModel();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {saveAsModelLoading ? (
                <Spinner color="success" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Enregistrer comme modéle"
              )}
            </Button>
            <Button
              color="primary"
              outline
              type="button"
              onClick={async () => {
                setModelsType(null);
                await load_saveAndSend();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {saveAndSendLoading ? (
                <Spinner color="success" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Enregistrer comme modéle et envoyer"
              )}
            </Button>
            <Button
              color="primary"
              type="button"
              onClick={async () => {
                setModelsType(null);
                await load_send();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {sendLoading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Envoyer"
              )}
            </Button>
            <Button
              color="success"
              type="button"
              onClick={async () => {
                setModelsType(null);
                await load_download();
              }}
              disabled={
                downloadLoading ||
                sendLoading ||
                saveAndSendLoading ||
                saveAsModelLoading
              }
            >
              {downloadLoading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Télécharger"
              )}
            </Button>
          </ModalFooter>
        </Modal>
        {/*edit model*/}
        <Modal
          className="modal-primary modal-dialog-centered fill-invoice-modal"
          isOpen={modelEditModal}
          size={"xl"}
          toggle={async () => {
            await toggleEditModelModal();
          }}
        >
          <ModalHeader
            toggle={async () => {
              await toggleEditModelModal();
            }}
          >
            Modifier un modèle
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5">
              <EditModel
                model={singleModel!}
                invoiceSubCategs={invoiceSubCategs}
                formData={formData}
                setFormData={setFormData}
                needEmail={needEmail}
                setNeedEmail={setNeedEmail}
                clientType={{
                  label:
                    singleModel?.clientType === ClientTypes.B2B ? "B2B" : "B2C",
                  value: singleModel?.clientType,
                }}
                needModelRef={needModelRef}
                setNeedModelRef={setNeedModelRef}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              outline
              type="button"
              onClick={async () => {
                await toggleEditModelModal();
              }}
              disabled={saveModelLoading}
            >
              Retour
            </Button>
            <Button
              color="success"
              type="button"
              onClick={async () => {
                await saveModel();
              }}
              disabled={saveModelLoading}
            >
              {saveModelLoading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Enregistrer le modéle"
              )}
            </Button>
          </ModalFooter>
        </Modal>
        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </div>
  );
};

export default Facturation;
