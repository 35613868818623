import React, { useEffect, useState } from "react";
import "./ListesFactures.scss";
import DataTable from "react-data-table-component";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { data } from "../../../constants";
import { BsTrashFill } from "react-icons/bs";
import { BootyPagination } from "../../../components/table/pagination";
import { RiSearchLine } from "react-icons/ri";
import CalenderIconBlue from "../../../assets/images/svg/calender-icon-blue.svg";
import Select, { SingleValue } from "react-select";
import ReviewIcon from "../../../assets/ReviewIcon";
import FactureHtml from "../FactureHtml/FactureHtml";
import FactureRev from "../FactureRev/FactureRev";
import config from "../../../config";
import axios from "axios";
import { useSelector } from "react-redux";
import { Company, IFacture, User } from "../../../interfaces";
import { ErrorLogger } from "../../../util/errorLogger";
import moment, { Moment } from "moment";
import { BILL_OPTIONS, OptionType, UserTypes } from "../../../util/context";
import Status from "../../../components/badge/Status";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import useAxios from "../../../util/hooks/useAxios";
import { useFormatter } from "../../../util/hooks/useFormatter";
import { IoMdCreate } from "react-icons/io";
import SelectableBadge from "../../../components/SelectableBadge";
import { toast, ToastContainer } from "react-toastify";
import FileViewer from "../../../components/FileViewer";
import DownloadLink from "react-download-link";
import { FiDownload } from "react-icons/fi";
import FileSaver from "file-saver";
registerLocale("fr", fr);
moment.updateLocale("fr", {});

export interface FactProps {}
type DynamicObj = {
  [prop: string]: boolean;
};

type SearchFacturesFormValues = {
  dateFrom?: string | null;
  dateTo?: string | null;
  company?: OptionType | null;
  status?: OptionType | null;
};

const { API_URL } = config[process.env.NODE_ENV];

const ListesFactures = ({
  client,
  context,
  company,
  callback,
  reload,
}: {
  client?: User;
  context?: string;
  company?: Company;
  callback?: any;
  reload?: boolean;
}) => {
  const [sendFactureLoader, setSendFactureLoader] = useState(false);
  const [deleteFactureLoader, setDeleteFactureLoader] = useState(false);
  const [downloadFactureLoader, setDownloadFactureLoader] = useState<
    null | string
  >(null);
  const [open, setOpen] = useState(false);
  const [factures, setFactures] = useState<IFacture[]>([]);
  const [singleFacture, setSingleFacture] = useState<IFacture | null>();
  const [loading, setLoading] = useState(false);
  const [openFactureModal, setOpenFactureModal] = useState<boolean>(false);
  const [deleteFactureModal, setDeleteFactureModal] = useState<boolean>(false);
  const [editFactureModal, setEditFactureModal] = useState<boolean>(false);
  const [sendFactureModal, setSendFactureModal] = useState<boolean>(false);
  const [emailSendError, setEmailSendError] = useState<boolean>(false);

  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);

  const ontoggle = () => {
    setOpen(true);
  };
  const onClose = () => {
    {
      setSingleFacture(null);
      setOpenFactureModal(false);
    }
  };

  const [pending, setPending] = useState<boolean>(true);

  const { setDecimalDigits } = useFormatter();
  const [showDropdown, setShowDropdown] = useState<DynamicObj>({});
  const [editStatusLoading, setEditStatusLoading] = useState<{
    index: string;
    value: boolean;
  } | null>();

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };
  let api = useAxios();

  const { user, company: userCompany } = useSelector(
    (state: { root: object; user: object; company: object }) => state.user
  ) as { user: User; company: Company };

  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const columns: any = React.useMemo(
    () => [
      {
        name: "Nº quittance",
        selector: (row: any) => String(row.id).toUpperCase(),
        sortable: true,
      },
      {
        name: "Société",
        selector: (row: any) =>
          // i know it's stupid,don't judge...
          row.clientInfo &&
          row.clientInfo.clientName &&
          row.clientInfo.clientName !== ""
            ? row.clientInfo.clientName
            : "",
        sortable: true,
      },
      {
        name: "Total HT",
        selector: (row: any) => `${setDecimalDigits(row.totalHT)} €`,
        sortable: true,
      },
      {
        name: "Total TTC",
        selector: (row: any) => `${setDecimalDigits(row.totalTTC)} €`,
        sortable: true,
      },
      {
        name: "Statut",
        sortable: true,
        cell: (row: IFacture) => (
          <>
            {/* {creds.role === UserTypes.Client ? ( */}
            <>
              {parseInt(row.status) === 100 ? (
                <Status className="btn" type="primary" title="Payée"></Status>
              ) : (
                <SelectableBadge
                  options={BILL_OPTIONS}
                  type={parseInt(row.status) === 102 ? "warning" : "danger"}
                  defaultValue={
                    BILL_OPTIONS.find((elt) => {
                      if (row?.status) {
                        return elt.value === parseInt(row?.status);
                      } else {
                        return elt.value === 102;
                      }
                    })!
                  }
                  onChange={async (val: any) =>
                    await editFactureStatus(row?.id!, val)
                  }
                  setShowDropdown={setShowDropdown}
                  showDropdown={showDropdown[row.id]}
                  index={row.id}
                  loading={editStatusLoading!}
                />
              )}
            </>
            {/* ) : (
              <>
                {parseInt(row.status) === 100 && (
                  <Status className="btn" type="primary" title="Payée"></Status>
                )}

                {parseInt(row.status) === 102 && (
                  <Status
                    className="btn"
                    type="warning"
                    title="En attente"
                  ></Status>
                )}

                {parseInt(row.status) === 101 && (
                  <Status
                    className="btn"
                    type="danger"
                    title="Non payée"
                  ></Status>
                )}
              </>
            )} */}
          </>
        ),
      },
      {
        name: "Date de création",
        selector: (row: any) => moment(row.createdAt).format("DD/MM/YYYY"),
        sortable: true,
      },
      {
        name: "Action",
        button: true,
        // omit: creds.role !== UserTypes.Client,
        cell: (row: IFacture) => (
          <>
            <div className="table-action">
              {parseInt(row.status) !== 100 && (
                <>
                  {row.clientInfo.clientEmail && (
                    <button
                      className="btn btn-blue"
                      onClick={() => {
                        setSingleFacture(row);
                        setSendFactureModal(true);
                      }}
                      title={`Renvoyer la facture ${
                        row.clientInfo.clientEmail
                          ? `à ${row.clientInfo.clientEmail}`
                          : ""
                      }`}
                    >
                      <ReviewIcon />
                    </button>
                  )}
                  {/* <button
                  className="btn btn-blue"
                  onClick={() => {
                    setSingleFacture(row);
                    setEditFactureModal(true);
                  }}
                  title="Editer le statut de la facture"
                >
                  <IoMdCreate />
                </button> */}
                  {/* <button
                  className="btn btn-red"
                  onClick={() => {
                    setSingleFacture(row);
                    setDeleteFactureModal(true);
                  }}
                  title="Supprimer la facture"
                >
                  <BsTrashFill />
                </button> */}
                </>
              )}
              {/* {parseInt(row.status) === 100 && ( */}
                <>
                  <button
                    className="btn btn-green"
                    onClick={async () => {
                      try {
                        let facture = row;
                        setDownloadFactureLoader(facture.id);
                        if (!facture.isUploaded && !facture.billS3link) {
                          const { data: createinvoicePdf } = await api.post(
                            `/api/invoice/createInvoicePdf`,
                            {
                              invoiceModel: "facture",
                              invoiceId: facture.id,
                            },
                            {
                              headers: {
                                "x-access-token": creds.token,
                              },
                            }
                          );
                          if (createinvoicePdf) {
                            const { data: invoiceCreated } = await api.post(
                              `/api/Facture`,
                              {
                                id: facture.id,
                              },
                              {
                                headers: {
                                  "x-access-token": creds.token,
                                },
                              }
                            );

                            if (invoiceCreated.id) {
                              facture = invoiceCreated;
                              downloadFacture(facture);
                              return;
                            }
                          }
                        }
                        downloadFacture(facture);
                      } catch (error) {
                        console.log("error creating facture pdf : ", error);
                      }
                    }}
                    disabled={
                      downloadFactureLoader && downloadFactureLoader !== row.id
                        ? true
                        : false
                    }
                  >
                    {downloadFactureLoader &&
                    downloadFactureLoader === row.id ? (
                      <Spinner color="green" type="border" size={"sm"}>
                        Loading...
                      </Spinner>
                    ) : (
                      <FiDownload />
                    )}
                  </button>
                </>
               {/* )} */}
              {creds.role !== UserTypes.Client && (
                <>
                  <button
                    className="btn btn-red"
                    onClick={() => {
                      setSingleFacture(row);
                      setDeleteFactureModal(true);
                    }}
                  >
                    <BsTrashFill />
                  </button>
                </>
              )}
            </div>
          </>
        ),
      },
    ],
    [creds.role, showDropdown,downloadFactureLoader]
  );

  const handlePageChange = async (page: number) => {
    setCurrentPage(page);
  };

  const getFactures = async () => {
    try {
      const { data } = await api.post(
        `/api/Facture/all`,
        {
          where: {
            companyId: company && company.id ? company?.id : userCompany.id,
            // there are 1 or more statuses that should't appear here like the status for imported factures 999
            status: ["100", "101", "102"],
          },
          perPage: 20,
          pageIndex: currentPage,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      setFactures(data.data);
      for (let elt of data.data) {
        setShowDropdown((prevState) => {
          return {
            ...prevState,
            [elt.id]: false,
          };
        });
      }
      setTotalRows(data.count);
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("getting factures data", error);
    }
  };

  const downloadFacture = (row: IFacture) => {
    try {
      FileSaver(row.billS3link.url, row.billS3link.key);
      setDownloadFactureLoader(null);
    } catch (error: any) {
      ErrorLogger("downloading facture", error);
      setDownloadFactureLoader(null);
      toast.dismiss();
      toast.warning(
        `On peut pas traiter votre facture en ce moment!
        `,
        {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };

  useEffect(() => {
    getFactures();
  }, [currentPage]);

  useEffect(() => {
    if (reload || creds.company_id) {
      getFactures();
      callback!(false);
    }
  }, [reload,creds.company_id]);

  const deleteFacture = async (id: string) => {
    try {
      setDeleteFactureLoader(true)
      const { data } = await api.post(
        `/api/Facture/Delete`,
        {
          id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      await getFactures();
      setDeleteFactureLoader(false)
      setDeleteFactureModal(false);
      if (!data.deleted || data.deleted.length === 0) {
        toast.dismiss();
        toast.warning(
          `Votre facture ne pouvait pas être supprimée, veuillez réessayer plus tard.`,
          {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } else {
        toast.dismiss();
        toast.success(`Votre facture a été supprimée.`, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error: any) {
      ErrorLogger("getting factures data", error);
      setDeleteFactureLoader(false)
      setDeleteFactureModal(false);
    }
  };

  const editFactureStatus = async (
    id: string,
    status: SingleValue<OptionType>
  ) => {
    try {
      toast.dismiss();
      toast.warning(
        "Cela peut prendre quelques minutes, veuillez patienter...",
        {
          position: "bottom-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      setEditStatusLoading({
        index: id,
        value: true,
      });
      await api.post(
        `/api/Facture/Update`,
        {
          id,
          status: status?.value,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      await getFactures();
      setEditStatusLoading(null);
      toast.dismiss();
    } catch (error: any) {
      ErrorLogger("getting factures data", error);
      setEditStatusLoading(null);
    }
  };

  const sendFacture = async (facture: IFacture) => {
    try {
      setSendFactureLoader(true);
      const { data } = await api.post(
        `/api/invoice/sendBillEmail`,
        {
          invoiceId: facture.id,
          invoiceModel: "facture",
          paymentLink: facture.billInfo.paymentLink,
          isReciept: false,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setSendFactureModal(false);
      setSendFactureLoader(false);
      if (!data.send) {
        setEmailSendError(true);
        toast.dismiss();
        toast.warning(
          `Votre facture ne pouvait pas être envoyée, veuillez réessayer plus tard.`,
          {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        return;
      } else {
        toast.dismiss();
        toast.success(`Votre facture a été envoyée.`, {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error: any) {
      ErrorLogger("sending facture", error);
      setEmailSendError(true);
      setSendFactureLoader(false);
    }
  };

  const resetSearchForm = async () => {
    searchReset({
      dateFrom: null,
      dateTo: null,
      company: null,
      status: null,
    });
    await getFactures();
  };

  const {
    control: searchControl,
    register: searchRegister,
    handleSubmit: searchHandleSubmit,
    reset: searchReset,
    formState: { errors: searchErrors },
  } = useForm<SearchFacturesFormValues>({});

  const searchFactures: SubmitHandler<SearchFacturesFormValues> = async (
    form: SearchFacturesFormValues
  ) => {
    try {
      setLoading(true);
      setPending(true);
      let payload: any = {
        companyId: company && company.id ? company?.id : userCompany.id,
        status: ["100", "101", "102"],
      };
      if (form.dateFrom || form.dateTo)
        payload.createdAt = {
          from: form.dateFrom
            ? moment(form.dateFrom).format()
            : moment("2019/01/01").format(),
          to: form.dateTo
            ? moment(form.dateTo).add(1, "day").format()
            : moment().add(1, "day").format(),
        };

      if (form.status) payload.status = String(form.status.value);

      const { data } = await api.post(
        `/api/Facture/All`,
        {
          where: {
            ...payload,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setFactures(data.data);
      setLoading(false);
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("searching factures", error);
    }
  };

  // search email form
  const { ref: dateToRef, ...dateTo } = searchRegister("dateTo");
  const { ref: dateFromRef, ...dateFrom } = searchRegister("dateFrom");

  return (
    <div>
      <div className="search-top custom_search-top">
        <form onSubmit={searchHandleSubmit(searchFactures)}>
          <Row className="align-items-end">
            <Col md={8}>
              <div className="filterInner">
                <Row>
                  <Col md={4}>
                    <FormGroup className="form-icon icon-end">
                      <Label for="dated">Date de début</Label>
                      <Controller
                        control={searchControl}
                        name="dateFrom"
                        render={({ field }) => (
                          <DatePicker
                            placeholderText="Date de début"
                            onChange={(date: any) => field.onChange(date)}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-secondary"
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      <span className="icon icon-secondary ">
                        <img src={CalenderIconBlue} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="form-icon icon-end">
                      <Label for="datef">Date de fin</Label>
                      <Controller
                        control={searchControl}
                        name="dateTo"
                        render={({ field }) => (
                          <DatePicker
                            placeholderText="Date de fin"
                            onChange={(date: any) => field.onChange(date)}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-secondary"
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      <span className="icon icon-secondary ">
                        <img src={CalenderIconBlue} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="pwd">Statut</Label>
                      <Controller
                        name="status"
                        control={searchControl}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={BILL_OPTIONS}
                            closeMenuOnSelect={true}
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={4}>
              <div className="actionsFilter mb-3">
                <Button color="secondary" type="submit">
                  <span>Filtrer</span>
                </Button>
                <Button
                  color="secondary"
                  outline
                  type="button"
                  onClick={async () => await resetSearchForm()}
                >
                  <span>Réinitialiser</span>
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
      <div>
        <Card className="card-Table table-primary invoice-table">
          <DataTable
            columns={columns}
            data={factures}
            noDataComponent={<p>Il n'y a aucun data à afficher</p>}
            onRowClicked={(row: IFacture, e: any) => {
              if (row.isUploaded && row.billS3link) {
                setUrl(row.billS3link.url);
                setViewModal(true);
                return;
              }

              setSingleFacture(row);
              setOpenFactureModal(true);
            }}
            pagination
            progressPending={pending}
            progressComponent={
              <>
                <Spinner color="secondary" type="grow" className="mx-1">
                  Loading...
                </Spinner>
                <Spinner color="secondary" type="grow" className="mx-1">
                  Loading...
                </Spinner>
                <Spinner color="secondary" type="grow" className="mx-1">
                  Loading...
                </Spinner>
              </>
            }
            paginationComponent={(props) => {
              const customProps = { ...props, color: "primary" };
              return <BootyPagination {...customProps} />;
            }}
            paginationServer
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            paginationPerPage={20}
          />
        </Card>
      </div>

      <div className="openbtn text-center">
        {/*view*/}
        <Modal
          className="modal-secondary modal-dialog-centered modal-lg facture-modal"
          isOpen={openFactureModal}
          toggle={() => {
            setSingleFacture(null);
            setOpenFactureModal(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setSingleFacture(null);
              setOpenFactureModal(false);
            }}
          >
            Prévisualisation de la facture {singleFacture?.id.toUpperCase()}
          </ModalHeader>
          <ModalBody>
            <div className="content-form-block">
              <FactureHtml
                invoice={singleFacture!}
                client={client}
                type={"facture"}
              />
            </div>
          </ModalBody>
        </Modal>
        {/*delete*/}
        <Modal
          className="modal-danger modal-dialog-centered"
          isOpen={deleteFactureModal}
          toggle={() => {
            setDeleteFactureModal(false);
            setSingleFacture(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setDeleteFactureModal(false);
              setSingleFacture(null);
            }}
          >
            Supprmier la facture {singleFacture?.id.toUpperCase()}
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir supprimer la facture{" "}
                {singleFacture?.id.toUpperCase()} ?
              </p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              outline
              onClick={() => {
                setDeleteFactureModal(false);
                setSingleFacture(null);
              }}
              disabled={deleteFactureLoader}
            >
              Non
            </Button>
            <Button
              color="danger"
              onClick={async () => await deleteFacture(singleFacture?.id!)}
              disabled={deleteFactureLoader}
            >
              {deleteFactureLoader ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Oui"
              )}
            </Button>
          </ModalFooter>
        </Modal>
        {/*edit*/}
        <Modal
          className="modal-secondary modal-dialog-centered status-change-modal"
          isOpen={editFactureModal}
          toggle={() => {
            setEditFactureModal(false);
            setSingleFacture(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setEditFactureModal(false);
              setSingleFacture(null);
            }}
          >
            Editer le statut de la facture {singleFacture?.id.toUpperCase()}
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5">
              <div className="acoounts-choice multiple-sync-wrapper ">
                <div className="bank-wrapper">
                  <FormGroup>
                    <Label>Sélectionner un statut</Label>
                    <Select
                      options={BILL_OPTIONS}
                      closeMenuOnSelect={true}
                      classNamePrefix="select"
                      className="custom-select form-secondary"
                      onChange={async (status) =>
                        await editFactureStatus(singleFacture?.id!, status!)
                      }
                      defaultValue={BILL_OPTIONS.find(
                        (elt) => elt.value === parseInt(singleFacture?.status!)
                      )}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </ModalBody>
          {/* <ModalFooter>
            <Button
              color="danger"
              outline
              onClick={() => {
                setEditFactureModal(false);
                setSingleFacture(null);
              }}
            >
              Non
            </Button>
            <Button
              color="danger"
              onClick={async () => await deleteFacture(singleFacture?.id!)}
            >
              Oui
            </Button>
          </ModalFooter> */}
        </Modal>
        {/*send*/}
        <Modal
          className="modal-warning modal-dialog-centered"
          isOpen={sendFactureModal}
          toggle={() => {
            setSendFactureModal(false);
            setSingleFacture(null);
            setEmailSendError(false);
            setSendFactureLoader(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setSendFactureModal(false);
              setSingleFacture(null);
              setEmailSendError(false);
              setSendFactureLoader(false);
            }}
          >
            Envoyer la facture {singleFacture?.id.toUpperCase()}
          </ModalHeader>

          <ModalBody>
            <div className="content-text p-lg-5">
              <p className="msg-text">
                Vous êtes sur de vouloir envoyer la facture{" "}
                {singleFacture?.id.toUpperCase()} vers{" "}
                {singleFacture?.clientInfo.clientEmail ||
                  singleFacture?.clientInfo.clientCompany}{" "}
                ?
              </p>
            </div>
            {emailSendError && (
              <Alert color="danger" className="mt-2">
                Nous ne pouvons pas envoyer d'email au client pour le moment.
              </Alert>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="warning"
              outline
              disabled={sendFactureLoader}
              onClick={() => {
                setSendFactureModal(false);
                setSingleFacture(null);
                setEmailSendError(false);
                setSendFactureLoader(false);
              }}
            >
              Non
            </Button>
            <Button
              color="warning"
              onClick={async () => await sendFacture(singleFacture!)}
              disabled={sendFactureLoader}
            >
              {sendFactureLoader ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Oui"
              )}
            </Button>
          </ModalFooter>
        </Modal>
        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </div>
  );
};

export default ListesFactures;
