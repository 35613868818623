import React, { useEffect, useState } from "react";
import "./SousCategories.scss";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { data } from "../../../constants";

import Select from "react-select";
import subCatIcon from "../../../assets/images/svg/sous-cat-icon.svg";
import TvaIcon from "../../../assets/images/svg/file-icon.svg";
import TvaBlueIcon from "../../../assets/images/svg/file-icon-blue.svg";
import QrCodeIcon from "../../../assets/images/svg/qr-icon.svg";
import QrCodeBlueIcon from "../../../assets/images/svg/qr-code.svg";
import KeyIcon from "../../../assets/images/svg/key-orange.svg";
import KeyBlueIcon from "../../../assets/images/svg/key-blue.svg";
import ListsIcon from "../../../assets/images/svg/lists-icons.svg";
import ListsBlueIcon from "../../../assets/images/svg/lists-icons-blue.svg";
import SubCategIcon from "../../../assets/images/svg/cats-icon.svg";
import SubCategBlueIcon from "../../../assets/images/svg/cats-icon-blue.svg";
import MontantIcon from "../../../assets/images/svg/money-icon.svg";
import MontantIconBlue from "../../../assets/images/svg/money-icon-blue.svg";

import { BootyPagination } from "../../../components/table/pagination";
import { BsTrashFill } from "react-icons/bs";
import DataTable from "react-data-table-component";
import { RiCloseLine, RiSearchLine } from "react-icons/ri";
import GridIcon from "../../../assets/GridIcon";
import { IoMdCreate } from "react-icons/io";
import ZoomIcon from "../../../assets/ZoomIcon";
import { ICategory, ISubCategory } from "../../../interfaces";
import { useSelector } from "react-redux";
import { ErrorLogger } from "../../../util/errorLogger";
import axios from "axios";
import config from "../../../config";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CategoriesTypes, OptionType } from "../../../util/context";
import useAxios from "../../../util/hooks/useAxios";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
const optionCat1 = [
  { value: "nouveauwhitelabel", label: "Nouveau white label" },
  { value: "digiwork", label: "Digiwork" },
  { value: "digiwork1", label: "Digiwork1" },
];

const SubCategoriesSubTypes = [
  { label: "Charges", value: "Charges" },
  {
    label: "Chiffre d'affaires",
    value: "Chiffre d'affaires",
  },
  {
    label: "Tiers",
    value: "Tiers",
  },
];
export interface ClientsProps {}

type SearchFormValues = {
  text?: string | null;
};

const { API_URL } = config[process.env.NODE_ENV];

type CreateFormValues = {
  sub_key: string | null;
  sub_TVA_check: boolean | null;
  sub_Is_Immob: boolean | null;
  sub_name: string | null;
  sub_description: string | null;
  sub_TVA: string | null;
  sub_activityCode: string | null;
  sub_counterpart: string | null;
  sub_amount: string | null;
  sub_yearsRangeMax: number | null;
  sub_yearsRangeMin: number | null;
  sub_redirectCategory: { value: string; label: string } | null;
  sub_second_category: { value: string; label: string } | null;
  // sub_type: { value: string; label: string } | null;
  sub_otherLabel: string | null;
  sub_rule: string | null;
  sub_subType: { value: string; label: string } | null;
  sub_isFacture: boolean | null;
  sub_category: { value: string; label: string } | null;
};

type EditFormValues = {
  key: string | null;
  name: string | null;
  description: string | null;
  TVA_check: boolean | null;
  Is_Immob: boolean | null;
  TVA: string | number | null;
  activityCode: string | null;
  counterpart: string | null;
  amount: string | null;
  yearsRangeMax: number | null;
  yearsRangeMin: number | null;
  yearsRange: number[] | null;
  redirectCategory: { value: string; label: string } | null;
  second_category: { value: string; label: string } | null;
  // type: { value: string; label: string } | null;
  otherLabel: string | null;
  rule: string | null;
  subType: { value: string; label: string } | null;
  isFacture: boolean | null;
  category: { value: string; label: string } | null;
  category_id?: string;
};

const SousCategories: React.FC<ClientsProps> = ({}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [createLoading, setCreateLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [closeCategSearch, setCloseCategSearch] = useState<boolean>(false);
  const [localCategories, setLocalCategories] = useState<OptionType[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [singleSubCategory, setSingleSubCategory] =
    useState<ISubCategory | null>();
  const [subCategories, setSubCategories] = useState<ISubCategory[]>([]);
  const [allSubCategories, setAllSubCategories] = useState<ISubCategory[]>([]);
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);

  const onEdittoggle = (subCategory: ISubCategory) => {
    setSingleSubCategory(subCategory);
    setEditOpen(true);
  };

  const onDeletetoggle = (subCategory: ISubCategory) => {
    setSingleSubCategory(subCategory);
    setDeleteOpen(true);
  };

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string };

  const [pending, setPending] = useState<boolean>(true);

  const columns: any = [
    {
      name: "Sous-catégorie",
      selector: (row: any) => row.name,
    },
    {
      name: "Catégorie",
      selector: (row: any) => row.category.name,
    },
    {
      name: "Clé",
      selector: (row: any) => row.key,
    },
    {
      name: "TVA",
      selector: (row: any) => row.TVA,
    },
    {
      name: "Type",
      selector: (row: any) =>
        SubCategoriesSubTypes.find((elt) => elt.value === row.subType)
          ? SubCategoriesSubTypes.find((elt) => elt.value === row.subType)
              ?.label
          : null,
      sortable: true,
    },

    {
      name: "Action",
      button: true,
      cell: (row: ISubCategory) => (
        <div className="table-action">
          <button className="btn btn-blue" onClick={() => onEdittoggle(row)}>
            <IoMdCreate />
          </button>
          <button className="btn btn-red" onClick={() => onDeletetoggle(row)}>
            <BsTrashFill />
          </button>
        </div>
      ),
    },
  ];

  let api = useAxios();

  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = async (page: number) => {
    // await getSubCategories(page);
    setCurrentPage(page);
  };

  const getSubCategories = async () => {
    try {
      setLoading(true);

      const { data } = await api.post(
        `/api/SubCategory/all`,
        {
          perPage: 20,
          pageIndex: currentPage,
          order: [
            ["key", "ASC"],
            ["id", "ASC"],
          ],
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const { data: all } = await api.post(
        `/api/SubCategory/all`,
        {
          perPage: 200,
          pageIndex: 1,
          order: [
            ["key", "ASC"],
            ["id", "ASC"],
          ],
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setSubCategories(data.data);
      setAllSubCategories(all.data.flat(1));
      setLoading(false);
      setTotalRows(data.count);
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("getting sub categories", error);
    }
  };

  useEffect(() => {
    getSubCategories();
  }, [currentPage]);

  const getCategories = async () => {
    try {
      setLoading(true);

      const { data } = await api.post(
        `/api/Category/all`,
        {},
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const reducedCatgories = data.data.reduce(
        (acc: any, curr: ICategory) =>
          [...acc, { label: curr.name, value: curr.id }] as {
            label: string;
            value: string;
          }[],
        []
      );

      setLocalCategories(reducedCatgories);
      setCategories(data.data);
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("getting categories", error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (singleSubCategory) {
      let payload: any = {
        key: singleSubCategory.key,
        name: singleSubCategory.name,
        TVA_check:
          singleSubCategory.TVA && String(singleSubCategory.TVA).includes("%")
            ? true
            : false,
        description: singleSubCategory.description,
        TVA: parseFloat(singleSubCategory.TVA),
        activityCode: singleSubCategory.activityCode,
        counterpart: singleSubCategory.counterpart,
        amount: singleSubCategory.amount,
        second_category: localCategories.find(
          (elt) => elt.value === singleSubCategory.second_category
        ) as { value: string; label: string },
        redirectCategory: {
          label: allSubCategories.find(
            (elt) => elt.id === singleSubCategory.redirectCategory_id
          )?.name as string,
          value: singleSubCategory.redirectCategory_id,
        },
        // type: SubCategoriesSubTypes.find(elt => elt.value === singleSubCategory.type) as { value: string; label: string },
        otherLabel: singleSubCategory.otherLabel as unknown as string,
        rule: singleSubCategory.rule as unknown as string,
        subType: SubCategoriesSubTypes.find(
          (elt) => elt.value === singleSubCategory.subType
        ) as { value: string; label: string },
        isFacture: singleSubCategory.isFacture,
        category: {
          label: singleSubCategory.category.name,
          value: singleSubCategory.category.id,
        },
      };
      if (singleSubCategory.yearsRange) {
        payload = {
          ...payload,
          yearsRangeMax: singleSubCategory.yearsRange[1] || 0,
          yearsRangeMin: singleSubCategory.yearsRange[0] || 0,
        };
      }
      editReset(payload);
    }
  }, [singleSubCategory]);

  //search form hook
  const {
    reset: searchReset,
    register: searchRegister,
    handleSubmit: searchHandleSubmit,
    formState: {},
  } = useForm<SearchFormValues>({});

  //create form hook
  const {
    watch: createWatch,
    reset: createReset,
    setValue: createSetValue,
    control: createControl,
    register: createRegister,
    handleSubmit: createHandleSubmit,
    formState: {},
  } = useForm<CreateFormValues>({});

  //edit form hook
  const {
    watch: editWatch,
    setValue: editSetValue,
    reset: editReset,
    control: editControl,
    register: editRegister,
    handleSubmit: editHandleSubmit,
    formState: {},
  } = useForm<EditFormValues>({});

  const {
    sub_rule: _sub_rule,
    sub_second_category: _sub_second_category,
    sub_TVA_check: _sub_TVA_check,
    sub_Is_Immob: _sub_Is_Immob,
  } = createWatch();
  const {
    rule: _rule,
    second_category: _second_category,
    TVA_check: _TVA_check,
    Is_Immob: _Is_Immob,
  } = editWatch();

  const searchSubCategory: SubmitHandler<SearchFormValues> = async (
    form: SearchFormValues
  ) => {
    try {
      setLoading(true);
      setPending(true);
      if (closeCategSearch) {
        searchReset();
        await getSubCategories();
        await getCategories();
        setCloseCategSearch(false);
      } else {
        setCloseCategSearch(true);
        const { data } = await api.post(
          `/api/SubCategory/all`,
          {
            where: {
              name: {
                iLike: `%${form.text}%`,
              },
            },
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
        setSubCategories(data.data);
        setLoading(false);
      }
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("category filter form", error);
    }
  };

  const createSubCategory: SubmitHandler<CreateFormValues> = async (
    form: CreateFormValues
  ) => {
    try {
      if (!form.sub_name || !form.sub_key || !form.sub_category) {
        setErrorMessage({
          type: "creation_empty_form",
          message:
            "Vérifiez que vous avez saisi un nom, une clé et une catégorie valides.",
        });
        return;
      }

      setCreateLoading(true);

      let payload: any = {
        key: form.sub_key,
        name: form.sub_name,
        description: form.sub_description || null,
        TVA: !form.sub_TVA_check
          ? "Non soumis à TVA"
          : `${form.sub_TVA}%` || null,
        activityCode: form.sub_activityCode || null,
        counterpart: form.sub_counterpart || null,
        amount: form.sub_amount || null,
        redirectCategory_id: form.sub_redirectCategory?.value || null,
        second_category: form.sub_second_category?.value || null,
        type: categories.find((elt) => elt.id === form.sub_category?.value)
          ? categories.find((elt) => elt.id === form.sub_category?.value)
              ?.type === CategoriesTypes.OUTPUT
            ? "OUTPUT"
            : "INPUT"
          : null,
        otherLabel: form.sub_otherLabel || null,
        rule: form.sub_rule === "true" ? true : false,
        subType: form.sub_subType?.value || null,
        isFacture: form.sub_rule === "true" ? true : false,
        category_id: form.sub_category?.value,
      };

      if (
        form.sub_Is_Immob &&
        form.sub_yearsRangeMax &&
        form.sub_yearsRangeMin
      ) {
        payload = {
          ...payload,
          yearsRange: [form.sub_yearsRangeMin, form.sub_yearsRangeMax],
        };
      }

      const { data } = await api.post(`/api/SubCategory/Create`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      setOpen(false);
      getSubCategories();
      createReset({
        sub_key: null,
        sub_name: null,
        sub_description: null,
        sub_TVA_check: null,
        sub_TVA: null,
        sub_activityCode: null,
        sub_counterpart: null,
        sub_amount: null,
        sub_redirectCategory: null,
        sub_second_category: null,
        // sub_type: null,
        sub_otherLabel: null,
        sub_rule: null,
        sub_subType: null,
        sub_isFacture: null,
        sub_category: null,
      });
      setCreateLoading(false);
    } catch (error: any) {
      ErrorLogger("creating sub-category form", error);
      setCreateLoading(false);
    }
  };

  const deleteSubCategory = async () => {
    try {
      setDeleteLoading(true);
      await api.post(
        `/api/SubCategory/deleteWhere`,
        {
          where: {
            id: [singleSubCategory?.id],
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setSubCategories((prevState) =>
        prevState.filter(
          (sub_category) => sub_category.id !== singleSubCategory?.id
        )
      );
      setSingleSubCategory(null);
      getSubCategories();
      setDeleteOpen(false);
      setDeleteLoading(false);
    } catch (error: any) {
      ErrorLogger("deleting a category", error);
      setDeleteLoading(false);
    }
  };

  const editSubCategory: SubmitHandler<EditFormValues> = async (
    form: EditFormValues
  ) => {
    try {
      setEditLoading(true);
      let initialVal: { [key: string]: any } = {
        key: singleSubCategory?.key,
        name: singleSubCategory?.name,
        description: singleSubCategory?.description,
        // TVA: singleSubCategory?.TVA,
        activityCode: singleSubCategory?.activityCode,
        counterpart: singleSubCategory?.counterpart,
        amount: singleSubCategory?.amount,
        second_category: localCategories.find(
          (elt) => elt.value === singleSubCategory?.second_category
        ),
        redirectCategory_id: {
          label: allSubCategories.find(
            (elt) => elt.id === singleSubCategory?.redirectCategory
          )?.name as string,
          value: singleSubCategory?.redirectCategory,
        },
        otherLabel: singleSubCategory?.otherLabel as unknown as string,
        rule: singleSubCategory?.rule as unknown as string,
        subType: singleSubCategory?.subType,
        isFacture: singleSubCategory?.isFacture,
        category: {
          label: singleSubCategory?.category.name,
          value: singleSubCategory?.category.id,
        },
      };

      const hasNotChanged = Object.keys(initialVal).every((elt: string) => {
        if (
          typeof (form as { [key: string]: any })[`${elt}`] === "object" &&
          (form as { [key: string]: any })[`${elt}`] &&
          typeof initialVal[`${elt}`] === "object" &&
          initialVal[`${elt}`]
        ) {
          return (
            initialVal[`${elt}`].value ===
            (form as { [key: string]: any })[`${elt}`].value
          );
        } else {
          return (
            initialVal[`${elt}`] === (form as { [key: string]: any })[`${elt}`]
          );
        }
      });

      if (hasNotChanged) {
        setEditLoading(false);
        setErrorMessage({
          type: "edit_empty_form",
          message: "Vous devez fournir des valeurs de mise à jour valides",
        });
        return;
      }

      let payload: Partial<EditFormValues> = {};

      if (form.name) payload.name = form.name;
      if (form.key) payload.key = form.key;
      if (form.description) payload.description = form.description;
      if (String(form.TVA) !== String(singleSubCategory?.TVA)) {
        payload.TVA = !form.TVA_check
          ? "Non soumis à TVA"
          : `${form.TVA}%` || null;
      }
      if (form.activityCode) payload.activityCode = form.activityCode;
      if (form.counterpart) payload.counterpart = form.counterpart;

      if (form.yearsRangeMax && singleSubCategory?.yearsRange) {
        payload.yearsRange = [
          singleSubCategory?.yearsRange[0],
          form.yearsRangeMax,
        ];
      }

      if (form.yearsRangeMin && singleSubCategory?.yearsRange) {
        payload.yearsRange = [
          form.yearsRangeMin,
          singleSubCategory?.yearsRange[1],
        ];
      }

      if (form.amount) payload.amount = form.amount;
      if (form.redirectCategory)
        ((payload as any).redirectCategory_id as unknown as string) =
          form.redirectCategory?.value;
      if (form.second_category)
        (payload.second_category as unknown as string) =
          form.second_category?.value;
      // if (form.type) (payload.type as unknown as string) = form.type?.value;k
      if (form.otherLabel) payload.otherLabel = form.otherLabel;
      if (form.rule) payload.rule = form.rule;
      if (form.subType) payload.subType = form.subType?.value as any;
      if (form.isFacture) payload.isFacture = form.isFacture;
      if (form.category) {
        (payload.category_id as unknown as string) = form.category?.value;
        (payload as any).type = categories.find(
          (elt) => elt.id === form.category?.value
        )
          ? categories.find((elt) => elt.id === form.category?.value)?.type ===
            CategoriesTypes.OUTPUT
            ? "OUTPUT"
            : "INPUT"
          : null;
      }
      await api.patch(
        `/api/SubCategory`,
        {
          id: singleSubCategory?.id,
          ...payload,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      setEditOpen(false);
      setSingleSubCategory(null);
      getSubCategories();
      setEditLoading(false);
    } catch (error: any) {
      ErrorLogger("updating sub-category form", error);
      setEditLoading(false);
    }
  };

  const { ref: textRef, ...text } = searchRegister("text");

  // create registers
  const { ref: sub_keyRef, ...sub_key } = createRegister("sub_key");
  const { ref: sub_nameRef, ...sub_name } = createRegister("sub_name");
  const { ref: sub_TVARef, ...sub_TVA } = createRegister("sub_TVA");
  const { ref: sub_descriptionRef, ...sub_description } =
    createRegister("sub_description");
  const { ref: sub_activityCodeRef, ...sub_activityCode } =
    createRegister("sub_activityCode");
  const { ref: sub_counterpartRef, ...sub_counterpart } =
    createRegister("sub_counterpart");
  const { ref: sub_amountRef, ...sub_amount } = createRegister("sub_amount");

  const { ref: sub_yearsRangeMaxRef, ...sub_yearsRangeMax } =
    createRegister("sub_yearsRangeMax");
  const { ref: sub_yearsRangeMinRef, ...sub_yearsRangeMin } =
    createRegister("sub_yearsRangeMin");

  // sub category here refers to the category of the sub category but with prefix sub_
  const { ref: sub_categoryRef, ...sub_category } =
    createRegister("sub_category");
  const { ref: sub_isFactureRef, ...sub_isFacture } =
    createRegister("sub_isFacture");
  const { ref: sub_ruleRef, ...sub_rule } = createRegister("sub_rule");
  const { ref: sub_Is_ImmobRef, ...sub_Is_Immob } =
    createRegister("sub_Is_Immob");

  // edit registers
  const { ref: keyRef, ...key } = editRegister("key");
  const { ref: nameRef, ...name } = editRegister("name");
  const { ref: TVARef, ...TVA } = editRegister("TVA");
  const { ref: descriptionRef, ...description } = editRegister("description");
  const { ref: activityCodeRef, ...activityCode } =
    editRegister("activityCode");
  const { ref: counterpartRef, ...counterpart } = editRegister("counterpart");
  const { ref: amountRef, ...amount } = editRegister("amount");
  const { ref: categoryRef, ...category } = editRegister("category");
  const { ref: isFactureRef, ...isFacture } = editRegister("isFacture");
  const { ref: ruleRef, ...rule } = editRegister("rule");

  const { ref: yearsRangeMaxRef, ...yearsRangeMax } =
    editRegister("yearsRangeMax");
  const { ref: yearsRangeMinRef, ...yearsRangeMin } =
    editRegister("yearsRangeMin");

  useEffect(() => {
    if (singleSubCategory?.second_category !== _second_category?.value) {
      editSetValue("redirectCategory", null);
    }
  }, [_second_category]);

  return (
    <div className="page page-sous-categorie">
      <Card className="card-Table table-primary">
        <div className="search-top">
          <Row>
            <Col md={8}>
              <form
                onSubmit={searchHandleSubmit(searchSubCategory)}
                className="form-icon  icon-end"
              >
                <Input
                  id="mc"
                  {...text}
                  innerRef={textRef}
                  placeholder="Chercher une sous-catégorie"
                  type="text"
                  className="form-secondary"
                />
                <button type="submit" className="icon icon-secondary ">
                  {closeCategSearch ? <RiCloseLine /> : <ZoomIcon />}
                </button>
              </form>
            </Col>
            <Col md={4} className="btn-addCat">
              <button className="btn btn-warning" onClick={() => setOpen(true)}>
                <GridIcon /> <span>Ajouter une sous-catégorie</span>
              </button>
            </Col>
          </Row>
        </div>

        <DataTable
          columns={columns}
          noDataComponent={<p>Il n'y a aucun data à afficher</p>}
          data={subCategories}
          pagination
          paginationComponent={(props) => {
            const customProps = { ...props, color: "primary" };
            return <BootyPagination {...customProps} />;
          }}
          progressPending={pending}
          progressComponent={
            <>
              <Spinner color="secondary" type="grow" className="mx-1">
                Loading...
              </Spinner>
              <Spinner color="secondary" type="grow" className="mx-1">
                Loading...
              </Spinner>
              <Spinner color="secondary" type="grow" className="mx-1">
                Loading...
              </Spinner>
            </>
          }
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          paginationPerPage={20}
        />

        <div className="openbtn text-center">
          {/*delete sub-category*/}
          <Modal
            className="modal-danger"
            isOpen={deleteOpen}
            toggle={() => {
              setSingleSubCategory(null);
              setDeleteOpen(false);
              setDeleteLoading(false);
            }}
          >
            <ModalHeader
              toggle={() => {
                setSingleSubCategory(null);
                setDeleteOpen(false);
                setDeleteLoading(false);
              }}
            >
              Supprimer une sous catégorie
            </ModalHeader>
            <ModalBody>
              <div className="content-text p-lg-5">
                <p className="msg-text">
                  Vous êtes sur de vouloir supprimer la sous-catégorie{" "}
                  {singleSubCategory?.name}?
                </p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                outline
                onClick={() => {
                  setSingleSubCategory(null);
                  setDeleteOpen(false);
                  setDeleteLoading(false);
                }}
                disabled={deleteLoading}
              >
                Non
              </Button>
              <Button
                color="danger"
                type="submit"
                onClick={async () => await deleteSubCategory()}
                disabled={deleteLoading}
              >
                {deleteLoading ? (
                  <Spinner color="light" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Oui"
                )}
              </Button>
            </ModalFooter>
          </Modal>

          {/*create sub-category*/}
          <Modal
            className="modal-warning modal-dialog-centered modal-lg"
            isOpen={open}
            toggle={() => {
              setOpen(false);
              createReset({
                sub_key: null,
                sub_name: null,
                sub_TVA_check: null,
                sub_description: null,
                sub_TVA: null,
                sub_activityCode: null,
                sub_counterpart: null,
                sub_amount: null,
                sub_redirectCategory: null,
                sub_second_category: null,
                // sub_type: null,
                sub_otherLabel: null,
                sub_rule: null,
                sub_subType: null,
                sub_isFacture: null,
                sub_category: null,
              });
              setCreateLoading(false);
              setErrorMessage(null);
            }}
          >
            <ModalHeader
              toggle={() => {
                setOpen(false);
                createReset({
                  sub_key: null,
                  sub_name: null,
                  sub_description: null,
                  sub_TVA_check: null,
                  sub_TVA: null,
                  sub_activityCode: null,
                  sub_counterpart: null,
                  sub_amount: null,
                  sub_redirectCategory: null,
                  sub_second_category: null,
                  // sub_type: null,
                  sub_otherLabel: null,
                  sub_rule: null,
                  sub_subType: null,
                  sub_isFacture: null,
                  sub_category: null,
                });
                setCreateLoading(false);
                setErrorMessage(null);
              }}
            >
              Créer une nouvelle sous-catégorie
            </ModalHeader>
            <form onSubmit={createHandleSubmit(createSubCategory)}>
              <ModalBody>
                <div className="content-form-block">
                  <Row>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="scat">Nom de la sous-catégorie</Label>
                        <Input
                          id="scat"
                          innerRef={sub_nameRef}
                          {...sub_name}
                          placeholder="Nom de la sous-catégorie"
                          type="text"
                          className="form-warning"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                        />
                        <span className="icon icon-warning ">
                          <img src={subCatIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="tva">
                          {String(_sub_TVA_check) === "null" ||
                          String(_sub_TVA_check) === "false" ||
                          _sub_TVA_check === undefined
                            ? "Soumis à la TVA"
                            : "TVA en %"}
                        </Label>
                        {String(_sub_TVA_check) === "null" ||
                        String(_sub_TVA_check) === "false" ||
                        _sub_TVA_check === undefined ? (
                          <div className="form-line mt-3">
                            <div className="check-box check-warning">
                              <Input
                                type="radio"
                                id={`radio-5`}
                                value={"false"}
                                onClick={() =>
                                  createSetValue("sub_TVA_check", false)
                                }
                              />
                              <label htmlFor={`radio-5`}>Non</label>
                            </div>
                            <div className="check-box check-warning">
                              <Input
                                type="radio"
                                id={`radio-6`}
                                value={"true"}
                                onClick={() =>
                                  createSetValue("sub_TVA_check", true)
                                }
                              />
                              <label htmlFor={`radio-6`}>Oui</label>
                            </div>
                          </div>
                        ) : (
                          <>
                            <Input
                              id="tva"
                              innerRef={sub_TVARef}
                              {...sub_TVA}
                              placeholder="Tva"
                              type="number"
                              className="form-warning"
                              min={0}
                              max={100}
                              onWheel={(e) => (e.target as any).blur()}
                              step="0.5"
                              onChange={() => {
                                setErrorMessage(null);
                              }}
                            />
                            <span className="icon icon-warning ">
                              <img src={TvaIcon} alt="icon" />
                            </span>
                          </>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="key">Clé</Label>
                        <Input
                          id="key"
                          innerRef={sub_keyRef}
                          {...sub_key}
                          placeholder="Clé"
                          type="text"
                          className="form-warning"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                        />
                        <span className="icon icon-warning ">
                          <img src={KeyIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="code-act">Code d'activité</Label>
                        <Input
                          id="code-act"
                          innerRef={sub_activityCodeRef}
                          {...sub_activityCode}
                          placeholder="Code d'activité"
                          type="text"
                          className="form-warning"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                        />
                        <span className="icon icon-warning ">
                          <img src={QrCodeIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="code-counter">Contrepartie</Label>
                        <Input
                          id="code-counter"
                          innerRef={sub_counterpartRef}
                          {...sub_counterpart}
                          placeholder="Contrepartie"
                          type="text"
                          className="form-warning"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                        />
                        <span className="icon icon-warning ">
                          <img src={QrCodeIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Catégorie</Label>
                        <Controller
                          name="sub_category"
                          control={createControl}
                          render={({ field }: any) => (
                            <Select
                              {...field}
                              options={localCategories}
                              classNamePrefix="select"
                              className="custom-select form-warning"
                              closeMenuOnSelect={true}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="cpwd">Description</Label>
                        <Input
                          id="desc"
                          innerRef={sub_descriptionRef}
                          {...sub_description}
                          placeholder="Description"
                          type="text"
                          className="form-warning"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                        />
                        <span className="icon icon-warning ">
                          <img src={ListsIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Type</Label>
                        <Controller
                          name="sub_subType"
                          control={createControl}
                          render={({ field }: any) => (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              options={SubCategoriesSubTypes}
                              placeholder="Veuillez choisir un type"
                              classNamePrefix="select"
                              className="custom-select form-warning"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="d-flex line-form-label my-2">
                        <span className="label-span">
                          S'agit-il d'une immobilisation ?
                        </span>
                        <div className="form-line custom-form-line">
                          <div className="check-box check-warning">
                            <Input
                              type="radio"
                              id={`radio-7`}
                              innerRef={sub_Is_ImmobRef}
                              {...sub_Is_Immob}
                              defaultChecked={true}
                              value={"false"}
                            />
                            <label htmlFor={`radio-7`}>Non</label>
                          </div>
                          <div className="check-box check-warning">
                            <Input
                              type="radio"
                              id={`radio-8`}
                              innerRef={sub_Is_ImmobRef}
                              {...sub_Is_Immob}
                              value={"true"}
                            />
                            <label htmlFor={`radio-8`}>Oui</label>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {String(_sub_Is_Immob) !== "null" &&
                      String(_sub_Is_Immob) !== "false" &&
                      _sub_Is_Immob !== undefined && (
                        <Row className="mt-3">
                          <Col md={6}>
                            <FormGroup>
                              <Label for="yearsRange">Minimum d'années</Label>
                              <Input
                                id="yearsRange"
                                innerRef={sub_yearsRangeMinRef}
                                {...sub_yearsRangeMin}
                                placeholder="Min"
                                type="number"
                                className="form-warning"
                                min={0}
                                max={100}
                                onWheel={(e) => (e.target as any).blur()}
                                step="1"
                                onChange={() => {
                                  setErrorMessage(null);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="yearsRange">Maximum d'années</Label>
                              <Input
                                id="yearsRange"
                                innerRef={sub_yearsRangeMaxRef}
                                {...sub_yearsRangeMax}
                                placeholder="Max"
                                type="number"
                                className="form-warning"
                                min={0}
                                max={100}
                                onWheel={(e) => (e.target as any).blur()}
                                step="1"
                                onChange={() => {
                                  setErrorMessage(null);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                  </Row>
                  <Row>
                    <Col md={6}>
                      <div className="d-flex line-form-label my-2">
                        <span className="label-span">Y a-t-il une règle ?</span>
                        <div className="form-line custom-form-line">
                          <div className="check-box check-warning">
                            <Input
                              type="radio"
                              id={`radio-1`}
                              innerRef={sub_ruleRef}
                              {...sub_rule}
                              defaultChecked={true}
                              value={"false"}
                            />
                            <label htmlFor={`radio-1`}>Non</label>
                          </div>
                          <div className="check-box check-warning">
                            <Input
                              type="radio"
                              id={`radio-2`}
                              innerRef={sub_ruleRef}
                              {...sub_rule}
                              value={"true"}
                            />
                            <label htmlFor={`radio-2`}>Oui</label>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {_sub_rule === "true" && (
                    <div className="regle-block-form mt-3">
                      <Row>
                        <Col md={6}>
                          <FormGroup className="form-icon icon-start">
                            <Label for="montant">Montant</Label>
                            <Input
                              id="montant"
                              {...sub_amount}
                              innerRef={sub_amountRef}
                              placeholder="Montant"
                              type="text"
                              className="form-warning"
                              onChange={() => {
                                setErrorMessage(null);
                              }}
                            />
                            <span className="icon icon-warning ">
                              <img src={MontantIcon} alt="icon" />
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="exampleEmail">Catégorie</Label>
                            <Controller
                              name="sub_second_category"
                              control={createControl}
                              render={({ field }: any) => (
                                <Select
                                  {...field}
                                  options={localCategories}
                                  closeMenuOnSelect={true}
                                  name="second_category"
                                  classNamePrefix="select"
                                  className="custom-select form-warning"
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup className="form-icon icon-start">
                            <Label for="subcateg">Sous-catégorie</Label>
                            <Controller
                              name="sub_redirectCategory"
                              control={createControl}
                              render={({ field }: any) => (
                                <Select
                                  {...field}
                                  options={allSubCategories
                                    .filter((elt) => {
                                      return (
                                        elt.category_id ===
                                        _sub_second_category?.value
                                      );
                                    })
                                    .reduce(
                                      (acc, curr) => [
                                        ...acc,
                                        {
                                          label: curr.name as string,
                                          value: curr.id as string,
                                        },
                                      ],
                                      [] as {
                                        label: string;
                                        value: string;
                                      }[]
                                    )}
                                  closeMenuOnSelect={true}
                                  name="second_category"
                                  classNamePrefix="select"
                                  className="custom-select form-warning"
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>

                        {/*
                        commented notes untill we find a use case
                        */}
                        {/* <Col md={6}>
                          <div className="d-flex line-form-label">
                            <span className="label-span">
                              Ajouter une note ?
                            </span>

                            <div className="form-line">
                              <div className="check-box check-warning">
                                <Input
                                  type="radio"
                                  id="test2"
                                  name="radio-group"
                                />
                                <label htmlFor="test2">Non</label>
                              </div>
                              <div className="check-box check-warning">
                                <Input
                                  type="radio"
                                  id="test3"
                                  name="radio-group"
                                />
                                <label htmlFor="test3">Oui</label>
                              </div>
                            </div>
                          </div>
                        </Col> */}
                        <Col md={6}>
                          <div className="d-flex line-form-label align-items-end mt-2">
                            <span className="label-span">
                              Afficher
                              <br /> dans les factures ?
                            </span>

                            <div className="form-line">
                              <div className="check-box check-warning">
                                <Input
                                  type="radio"
                                  id="test2"
                                  {...sub_isFacture}
                                  innerRef={sub_isFactureRef}
                                  defaultValue={"false"}
                                />
                                <label htmlFor="test2">Non</label>
                              </div>
                              <div className="check-box check-warning">
                                <Input
                                  type="radio"
                                  id="test3"
                                  {...sub_isFacture}
                                  innerRef={sub_isFactureRef}
                                  defaultValue={"true"}
                                />
                                <label htmlFor="test3">Oui</label>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <Row className="mt-5">
                    {errorMessage?.type === "creation_empty_form" && (
                      <Alert color="danger">{errorMessage?.message}</Alert>
                    )}
                  </Row>
                </div>
              </ModalBody>

              <ModalFooter>
                <Button
                  color="warning"
                  outline
                  onClick={() => {
                    setOpen(false);
                    createReset({
                      sub_key: null,
                      sub_name: null,
                      sub_TVA_check: null,
                      sub_description: null,
                      sub_TVA: null,
                      sub_activityCode: null,
                      sub_counterpart: null,
                      sub_amount: null,
                      sub_redirectCategory: null,
                      sub_second_category: null,
                      // sub_type: null,
                      sub_otherLabel: null,
                      sub_rule: null,
                      sub_subType: null,
                      sub_isFacture: null,
                      sub_category: null,
                    });
                    setCreateLoading(false);
                    setErrorMessage(null);
                  }}
                  disabled={createLoading}
                >
                  Annuler
                </Button>
                <Button color="warning" type="submit" disabled={createLoading}>
                  {createLoading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Enregistrer"
                  )}
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          {/*edit sub-category*/}
          <Modal
            className="modal-secondary modal-dialog-centered modal-lg"
            isOpen={editOpen}
            toggle={() => {
              setSingleSubCategory(null);
              setEditOpen(false);
              setEditLoading(false);
              setErrorMessage(null);
            }}
          >
            <ModalHeader
              toggle={() => {
                setSingleSubCategory(null);
                setEditOpen(false);
                setEditLoading(false);
                setErrorMessage(null);
              }}
            >
              Editer la sous-catégorie
            </ModalHeader>
            <form onSubmit={editHandleSubmit(editSubCategory)}>
              <ModalBody>
                <div className="content-form-block">
                  <Row>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="scat">Nom de la sous-catégorie</Label>
                        <Input
                          id="scat"
                          innerRef={nameRef}
                          {...name}
                          placeholder="Nom de la sous-catégorie"
                          type="text"
                          className="form-secondary"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                        />
                        <span className="icon icon-secondary ">
                          <img src={SubCategBlueIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="tva">
                          {String(_TVA_check) === "null" ||
                          String(_TVA_check) === "false" ||
                          _TVA_check === undefined
                            ? "Soumis à la TVA"
                            : "TVA en %"}
                        </Label>
                        {String(_TVA_check) === "null" ||
                        String(_TVA_check) === "false" ||
                        _TVA_check === undefined ? (
                          <div className="form-line mt-3">
                            <div className="check-box check-secondary">
                              <Input
                                type="radio"
                                id={`radio-7`}
                                value={"false"}
                                onClick={() => editSetValue("TVA_check", false)}
                                defaultChecked={!_TVA_check}
                              />
                              <label htmlFor={`radio-7`}>Non</label>
                            </div>
                            <div className="check-box check-secondary">
                              <Input
                                type="radio"
                                id={`radio-8`}
                                value={"true"}
                                onClick={() => editSetValue("TVA_check", true)}
                              />
                              <label htmlFor={`radio-8`}>Oui</label>
                            </div>
                          </div>
                        ) : (
                          <>
                            <Input
                              id="tva"
                              innerRef={TVARef}
                              {...TVA}
                              placeholder="Tva"
                              type="number"
                              className="form-secondary"
                              min={0}
                              max={100}
                              onWheel={(e) => (e.target as any).blur()}
                              step="0.5"
                              onChange={() => {
                                setErrorMessage(null);
                              }}
                            />
                            <span className="icon icon-secondary ">
                              <img src={TvaBlueIcon} alt="icon" />
                            </span>
                          </>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="key">Clé</Label>
                        <Input
                          id="key"
                          innerRef={keyRef}
                          {...key}
                          placeholder="Clé"
                          type="text"
                          className="form-secondary"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                        />
                        <span className="icon icon-secondary ">
                          <img src={KeyBlueIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="code-act">Code d'activité</Label>
                        <Input
                          id="code-act"
                          innerRef={activityCodeRef}
                          {...activityCode}
                          placeholder="Code d'activité"
                          type="text"
                          className="form-secondary"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                        />
                        <span className="icon icon-secondary ">
                          <img src={QrCodeBlueIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="code-counter">Contrepartie</Label>
                        <Input
                          id="code-counter"
                          innerRef={counterpartRef}
                          {...counterpart}
                          placeholder="Contrepartie"
                          type="text"
                          className="form-secondary"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                        />
                        <span className="icon icon-secondary ">
                          <img src={QrCodeBlueIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Catégorie</Label>
                        <Controller
                          name="category"
                          control={editControl}
                          render={({ field }: any) => (
                            <Select
                              {...field}
                              options={localCategories}
                              classNamePrefix="select"
                              className="custom-select form-secondary"
                              closeMenuOnSelect={true}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="cpwd">Description</Label>
                        <Input
                          id="desc"
                          innerRef={descriptionRef}
                          {...description}
                          placeholder="Description"
                          type="text"
                          className="form-secondary"
                          onChange={() => {
                            setErrorMessage(null);
                          }}
                        />
                        <span className="icon icon-secondary ">
                          <img src={ListsBlueIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Type</Label>
                        <Controller
                          name="subType"
                          control={editControl}
                          render={({ field }: any) => (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              options={SubCategoriesSubTypes}
                              placeholder="Veuillez choisir un type"
                              classNamePrefix="select"
                              className="custom-select form-secondary"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    {singleSubCategory?.yearsRange && (
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="montant">Minimum d'années</Label>
                            <Input
                              id="yearsRange"
                              innerRef={yearsRangeMinRef}
                              {...yearsRangeMin}
                              placeholder="Min"
                              type="number"
                              className="form-secondary"
                              min={0}
                              max={100}
                              onWheel={(e) => (e.target as any).blur()}
                              step="1"
                              onChange={() => {
                                setErrorMessage(null);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="exampleEmail">Maximum d'années</Label>
                            <Input
                              id="yearsRange"
                              innerRef={yearsRangeMaxRef}
                              {...yearsRangeMax}
                              placeholder="Max"
                              type="number"
                              className="form-secondary"
                              min={0}
                              max={100}
                              onWheel={(e) => (e.target as any).blur()}
                              step="1"
                              onChange={() => {
                                setErrorMessage(null);
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    <Col md={6}>
                      <div className="d-flex line-form-label">
                        <span className="label-span">Y a-t-il une règle ?</span>
                        <div className="form-line">
                          {["false", "true"].map((value, index) => {
                            return (
                              <div
                                className="check-box check-secondary"
                                key={index}
                              >
                                <Input
                                  type="radio"
                                  id={`radio-${index}`}
                                  innerRef={ruleRef}
                                  {...rule}
                                  value={value}
                                  defaultChecked={
                                    String(singleSubCategory?.rule) === value
                                  }
                                />
                                <label htmlFor={`radio-${index}`}>
                                  {index === 0 ? "Non" : "Oui"}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {_rule === "true" ? (
                    <div className="regle-block-form">
                      <Row>
                        <Col md={6}>
                          <FormGroup className="form-icon icon-start">
                            <Label for="montant">Montant</Label>
                            <Input
                              id="montant"
                              {...amount}
                              innerRef={amountRef}
                              placeholder="Montant"
                              type="text"
                              className="form-secondary"
                              onChange={() => {
                                setErrorMessage(null);
                              }}
                            />
                            <span className="icon icon-secondary ">
                              <img src={MontantIconBlue} alt="icon" />
                            </span>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="exampleEmail">Catégorie</Label>
                            <Controller
                              name="second_category"
                              control={editControl}
                              render={({ field }: any) => (
                                <Select
                                  {...field}
                                  options={localCategories}
                                  closeMenuOnSelect={true}
                                  classNamePrefix="select"
                                  className="custom-select form-secondary"
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup className="form-icon icon-start">
                            <Label for="subcateg">Sous-catégorie</Label>
                            <Controller
                              name="redirectCategory"
                              control={editControl}
                              render={({ field }: any) => (
                                <Select
                                  {...field}
                                  options={allSubCategories
                                    .filter((elt) => {
                                      return (
                                        elt.category_id ===
                                        _second_category?.value
                                      );
                                    })
                                    .reduce(
                                      (acc, curr) => [
                                        ...acc,
                                        {
                                          label: curr.name as string,
                                          value: curr.id as string,
                                        },
                                      ],
                                      [] as {
                                        label: string;
                                        value: string;
                                      }[]
                                    )}
                                  closeMenuOnSelect={true}
                                  classNamePrefix="select"
                                  className="custom-select form-secondary"
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>

                        {/*
                        commented notes untill we find a use case
                        */}
                        {/* <Col md={6}>
                          <div className="d-flex line-form-label">
                            <span className="label-span">
                              Ajouter une note ?
                            </span>

                            <div className="form-line">
                              <div className="check-box check-secondary">
                                <input
                                  type="radio"
                                  id="test2"
                                  name="radio-group"
                                />
                                <label htmlFor="test2">Non</label>
                              </div>
                              <div className="check-box check-secondary">
                                <input
                                  type="radio"
                                  id="test3"
                                  name="radio-group"
                                />{errorMessage?.type === "edit_empty_form" && (
                        <Alert color="danger">{errorMessage?.message}</Alert>
                      )}
                                <label htmlFor="test3">Oui</label>
                              </div>
                            </div>
                          </div>
                        </Col> */}
                        <Col md={6}>
                          <div className="d-flex line-form-label align-items-end mt-2">
                            <span className="label-span">
                              Afficher
                              <br /> dans les factures ?
                            </span>

                            <div className="form-line">
                              <div className="check-box check-secondary">
                                <Input
                                  type="radio"
                                  id="test2"
                                  {...isFacture}
                                  innerRef={isFactureRef}
                                  defaultValue={"false"}
                                  onChange={() => {
                                    setErrorMessage(null);
                                  }}
                                />
                                <label htmlFor="test2">Non</label>
                              </div>
                              <div className="check-box check-secondary">
                                <Input
                                  type="radio"
                                  id="test3"
                                  {...isFacture}
                                  innerRef={isFactureRef}
                                  defaultValue={"true"}
                                  onChange={() => {
                                    setErrorMessage(null);
                                  }}
                                />
                                <label htmlFor="test3">Oui</label>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}

                  <Row className="mt-5">
                    {errorMessage?.type === "edit_empty_form" && (
                      <Alert color="danger">{errorMessage?.message}</Alert>
                    )}
                  </Row>
                </div>
              </ModalBody>

              <ModalFooter>
                <Button
                  color="secondary"
                  outline
                  onClick={() => {
                    setSingleSubCategory(null);
                    setEditOpen(false);
                    setEditLoading(false);
                    setErrorMessage(null);
                  }}
                  disabled={editLoading}
                >
                  Annuler
                </Button>
                <Button color="secondary" type="submit" disabled={editLoading}>
                  {editLoading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Enregistrer"
                  )}
                </Button>
              </ModalFooter>
            </form>
          </Modal>
        </div>
      </Card>
    </div>
  );
};

export default SousCategories;
