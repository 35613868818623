import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import CardDashboard from "../../components/CardDashboard/CardDashboard";
import ChiffreAffaireIcon from "../../assets/images/svg/chiffre-affaire-icon.svg";
import ChargeIcon from "../../assets/images/svg/charges-icon.svg";
import AvanceTvaIcon from "../../assets/images/svg/avance-tva.svg";
import TopIcon from "../../assets/images/svg/top-icon.svg";
import BottomIcon from "../../assets/images/svg/bottom-icon.svg";
import { Alert, Badge, Button, Card, Col, Row, Spinner } from "reactstrap";
import CardDashDepense from "../../components/CardDashDepense/CardDashDepense";
import EncaissemIcon from "../../assets/images/svg/encaissement-icons-dash.svg";
import IconImmobilier from "../../assets/images/svg/immobil-icons-dash.svg";
import PaieDiffert from "../../assets/images/svg/paie-diff-dash.svg";
import moment, { Moment } from "moment";
import { useFormatter } from "../../util/hooks/useFormatter";
import { Company, IS3, User } from "../../interfaces";
import { shallowEqual, useSelector } from "react-redux";
import config from "../../config";
import axios from "axios";
import { ErrorLogger } from "../../util/errorLogger";
import LineChartsMu from "./LineChartsMu/LineChartsMu";
import DepenseCharts from "./DepenseCharts/DepenseCharts";
import LineCharts from "./LineCharts/LineCharts";
import CircleCharts from "./CircleCharts/CircleCharts";
import { AppPacks, BankSynced, UserTypes } from "../../util/context";
import useAxios from "../../util/hooks/useAxios";
import CircleRed from "../../components/DashboardCircles/Red";
import Circles from "../../components/DashboardCircles";
import BannerTop from "../../components/Banner/BannerTop";
import UserInfo from "../../components/user-info/UserInfo";
import { RiUser3Line } from "react-icons/ri";
import { SiPypy } from "react-icons/si";
import data from "../../constants/data";
import CrownIcon from "../../assets/CrownIcon";
import { colors } from "../../constants";
import Thermometer from "../../components/Thermometer";
import FirstSyncComp from "../../components/FirstSyncComp";
moment.updateLocale("fr", {});

const { API_URL } = config[process.env.NODE_ENV];

export type TopCardsTypes = {
  VAT: number | null;
  IT: number | null;
  Spending: number | null;
  Differential: number | null;
  diffVAT: number | null;
  diffVATPercent: number | null;
  diffIT: number | null;
  diffITPercent: number | null;
  diffSpending: number | null;
  diffSpendingPercent: number | null;
  diffSpendingIt: number | null;
};

export type CAChartTypes = {
  result: Partial<TopCardsTypes>;
  numericMonth: string | null;
  longMonth: string | null;
  shortMonth: string | null;
  year: string | null;
};

export type spendingByCategory = {
  amount: number | null;
  transClass: string | null;
};

export type AnnualSoldeTresTypes = {
  annualVATSoldeTres: {
    soldeTresTotal: number | null;
    numericMonth: string | null;
    longMonth: string | null;
    shortMonth: string | null;
  }[];
  annualSoldeTresValue: number | null;
};

type Top3 = {
  amount: number | null;
  main_categ: string | null;
  iconLink: IS3 | null;
  percentage: string | null;
};

type StatsState = {
  caCahrtData: CAChartTypes[];
  monthlyEstimationData: CAChartTypes[];
  spendingByCategoryData: spendingByCategory[];
  annualSoldeTresData: AnnualSoldeTresTypes;
};

type TopCardsState = {
  topCardData: TopCardsTypes;
  top3Data: Top3[];
};

const Dashboard = ({
  context,
  company,
  callback,
}: {
  context?: string;
  company?: Company;
  callback?: () => void;
}) => {
  const [currentDate, setCurrentDate] = useState<string>(
    moment().format("YYYY-MM-DD")
  );

  const [loading, setLoading] = useState<boolean>(false);

  const [stats, setStats] = useState<StatsState>({
    caCahrtData: [],
    monthlyEstimationData: [],
    spendingByCategoryData: [],
    annualSoldeTresData: {
      annualVATSoldeTres: [],
      annualSoldeTresValue: 0,
    },
  });

  const [topCards, setTopCards] = useState<TopCardsState>({
    topCardData: {
      VAT: 0,
      IT: 0,
      Spending: 0,
      Differential: 0,
      diffVAT: 0,
      diffVATPercent: 0,
      diffIT: 0,
      diffITPercent: 0,
      diffSpending: 0,
      diffSpendingPercent: 0,
      diffSpendingIt: 0,
    },
    top3Data: [],
  });

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as {
    user_id: string;
    company_id: string;
    token: string;
    role: string;
    pack: string;
  };

  const { user, company: userCompany } = useSelector(
    (state: { root: object; user: object; company: object }) => state.user,
    shallowEqual
  ) as { user: User; company: Company };

  const { newNotif } = useSelector(
    (state: { messagesNotifs: object }) => state.messagesNotifs
  ) as { newNotif: boolean };

  const navigate = useNavigate();
  let api = useAxios();

  const { id } = useParams();

  const getTopCardsData = async () => {
    try {
      const { data } = await api.post(
        `/api/stats/cards`,
        {
          dateFrom: moment(currentDate).startOf("month").format("YYYY-MM-DD"),
          dateTo: moment(currentDate).endOf("month").format("YYYY-MM-DD"),
          companyId:
            creds.role === UserTypes.Client ? creds.company_id : company?.id,
          pack: creds.role === UserTypes.Client ? creds.pack : company?.pack,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      return data.cards.length > 0 && data.cards[0].result
        ? data.cards[0].result
        : {
            VAT: 0,
            IT: 0,
            Spending: 0,
            Differential: 0,
            diffVAT: 0,
            diffVATPercent: 0,
            diffIT: 0,
            diffITPercent: 0,
            diffSpending: 0,
            diffSpendingPercent: 0,
          };
    } catch (error: any) {
      ErrorLogger("getting top cards data in dashboard", error);
    }
  };

  const getTop3Data = async () => {
    try {
      const { data } = await api.post(
        `/api/stats/top3`,
        {
          dateFrom: moment(currentDate).startOf("month").format("YYYY-MM-DD"),
          dateTo: moment(currentDate).endOf("month").format("YYYY-MM-DD"),
          companyId:
            creds.role === UserTypes.Client ? creds.company_id : company?.id,
          pack: creds.role === UserTypes.Client ? creds.pack : company?.pack,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      return data.top3.length > 0
        ? data.top3.sort((a: { amount: number }, b: { amount: number }) => {
            return b.amount - a.amount;
          })
        : [];
    } catch (error: any) {
      ErrorLogger("getting top 3 data in dashboard", error);
    }
  };

  const getCAChartData = async () => {
    try {
      const { data } = await api.post(
        `/api/stats/caChart`,
        {
          companyId:
            creds.role === UserTypes.Client ? creds.company_id : company?.id,
          pack: creds.role === UserTypes.Client ? creds.pack : company?.pack,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      return data.caChart || [];
    } catch (error: any) {
      ErrorLogger("getting ca chart data in dashboard", error);
      setLoading(false);
    }
  };

  const getMonthlyEstimationData = async () => {
    try {
      const { data } = await api.post(
        `/api/stats/monthlyEstimations`,
        {
          companyId:
            creds.role === UserTypes.Client ? creds.company_id : company?.id,
          pack: creds.role === UserTypes.Client ? creds.pack : company?.pack,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      return data.estimations || [];
    } catch (error: any) {
      ErrorLogger("getting estimations data in dashboard", error);
      setLoading(false);
    }
  };

  const getAnnualSoldeTresData = async () => {
    try {
      const { data } = await api.post(
        `/api/stats/annualSoldeTres`,
        {
          dateFrom: moment(new Date()).startOf("year").format("YYYY-MM-DD"),
          dateTo: moment(new Date()).format("YYYY-MM-DD"),
          companyId:
            creds.role === UserTypes.Client ? creds.company_id : company?.id,
          pack: creds.role === UserTypes.Client ? creds.pack : company?.pack,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (data.annualVATSoldeTres) {
        return data;
      } else {
        return {
          annualVATSoldeTres: [],
          annualSoldeTresValue: 0,
        };
      }
    } catch (error: any) {
      ErrorLogger("getting annual solde tres data in dashboard", error);
      setLoading(false);
    }
  };

  const getSpendingByCategoryData = async () => {
    try {
      const { data } = await api.post(
        `/api/stats/spendingByCategory`,
        {
          companyId:
            creds.role === UserTypes.Client ? creds.company_id : company?.id,
          pack: creds.role === UserTypes.Client ? creds.pack : company?.pack,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      return data.spendingByCategory || [];
    } catch (error: any) {
      ErrorLogger("getting spendingByCategory data in dashboard", error);
      setLoading(false);
    }
  };

  const getStats = async () => {
    try {
      const caChartData = await getCAChartData();
      const monthlyEstimData = await getMonthlyEstimationData();
      const annualSoldeTresData = await getAnnualSoldeTresData();
      const spendingByCategData = await getSpendingByCategoryData();
      setStats({
        caCahrtData: caChartData,
        monthlyEstimationData: monthlyEstimData,
        spendingByCategoryData: spendingByCategData,
        annualSoldeTresData: annualSoldeTresData,
      });
    } catch (error: any) {
      console.log("getting stats : ", error);
    }
  };

  const getTopCards = async () => {
    try {
      const topCards = await getTopCardsData();
      const top3 = await getTop3Data();

      setTopCards({
        topCardData: topCards,
        top3Data: top3,
      });
    } catch (error: any) {
      console.log("getting stats : ", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    getStats();
    setLoading(false);
  }, []);

  const { setDecimalDigits } = useFormatter();

  useEffect(() => {
    getTopCards();
  }, [currentDate]);

  useEffect(() => {
    if (creds.company_id) {
      setLoading(true);
      getStats();
      setLoading(false);
      getTopCards();
    }
  }, [creds.company_id]);

  return (
    <div
      className={`page section-dashboard dashboard-selector-wrapper ${
        creds.role !== UserTypes.Client && "add-dashboard-padding"
      }`}
    >
      {creds.role === UserTypes.Client ? (
        <>
          <div
            className={`top-content ${
              creds.role === UserTypes.Client ? "client-resize-header" : ""
            }`}
          >
            <Row>
              <Col lg={8} md={12} className="blockBanner">
                <BannerTop banner={data.dashboard} />
              </Col>
              <Col lg={4} md={12}>
                <Card className="blockUser">
                  <Button
                    className="btn-icon btn-icon-primary"
                    color="light"
                    onClick={() => navigate("/tickets")}
                  >
                    {newNotif && <Badge color="primary"> </Badge>}
                    <CrownIcon stroke={colors.primaryColor} />
                  </Button>
                  <UserInfo user={user} />
                  <Button
                    className="btn-icon btn-icon-primary"
                    color="light"
                    onClick={() =>
                      navigate({
                        pathname: "/profil/client",
                        // search: "?page=1&tab=1",
                      })
                    }
                  >
                    <RiUser3Line />
                  </Button>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <></>
      )}
      {creds.role === UserTypes.Client &&
        userCompany.bankSynced !== BankSynced.YES && (
          <FirstSyncComp
            id={(creds.role === UserTypes.Client ? creds.company_id : id)!}
          />
        )}
      <div className="action-top-dash">
        <span className="month-label">
          {moment(currentDate).format("MMMM/YYYY")}
        </span>
        <div className="dash-wrapper">
          <button
            type="button"
            className="btn btn-outline-primary "
            onClick={() =>
              setCurrentDate(
                moment(currentDate).subtract(1, "month").format("YYYY-MM-DD")
              )
            }
          >
            Mois précédent
          </button>
          <button
            type="button"
            className="btn btn-primary "
            onClick={() =>
              setCurrentDate(
                moment(currentDate).add(1, "month").format("YYYY-MM-DD")
              )
            }
          >
            Mois suivant
          </button>
        </div>
      </div>
      <Row>
        <Col lg={8} md={12} sm={12}>
          <div className="card_header-top">
            <Row>
              <Col
                lg={
                  ![AppPacks.BNCAssoc, AppPacks.Entrepreneur].includes(
                    (company ? company : userCompany)
                      ?.pack as unknown as AppPacks
                  ) &&
                  !(
                    (company ? company : userCompany)?.pack ===
                      AppPacks.BICPlus &&
                    !(company ? company : userCompany).subjectToVAT
                  )
                    ? 4
                    : 6
                }
                sm={12}
              >
                <CardDashboard
                  title="Chiffre d’affaires"
                  text={
                    setDecimalDigits(Math.abs(topCards.topCardData.IT!)) + " €"
                  }
                  valuePrice={`${
                    topCards.topCardData.diffIT! > 0
                      ? `+ ${setDecimalDigits(
                          Math.abs(topCards.topCardData.diffIT!)
                        )}`
                      : `- ${setDecimalDigits(
                          Math.abs(topCards.topCardData.diffIT!)
                        )}`
                  } €`}
                  percent={`${setDecimalDigits(
                    topCards.topCardData.diffITPercent!
                  )}%`}
                  icon={ChiffreAffaireIcon}
                  iconEtat={
                    topCards.topCardData.diffIT! > 0 ? TopIcon : BottomIcon
                  }
                  className={`card-primary ${
                    ![AppPacks.BNCAssoc, AppPacks.Entrepreneur].includes(
                      (company ? company : userCompany)
                        ?.pack as unknown as AppPacks
                    ) &&
                    !(
                      (company ? company : userCompany)?.pack ===
                        AppPacks.BICPlus &&
                      !(company ? company : userCompany).subjectToVAT
                    )
                      ? ""
                      : "resize-card-blacklist"
                  }`}
                />
              </Col>
              <Col
                lg={
                  ![AppPacks.BNCAssoc, AppPacks.Entrepreneur].includes(
                    (company ? company : userCompany)
                      ?.pack as unknown as AppPacks
                  ) &&
                  !(
                    (company ? company : userCompany)?.pack ===
                      AppPacks.BICPlus &&
                    !(company ? company : userCompany).subjectToVAT
                  )
                    ? 4
                    : 6
                }
                sm={12}
              >
                <CardDashboard
                  title={
                    (company ? company : userCompany)?.pack ===
                    AppPacks.Entrepreneur
                      ? "Imposition Estimée"
                      : "Charges"
                  }
                  text={
                    setDecimalDigits(Math.abs(topCards.topCardData.Spending!)) +
                    " €"
                  }
                  valuePrice={`${
                    topCards.topCardData.diffSpending! > 0
                      ? `+ ${setDecimalDigits(
                          Math.abs(topCards.topCardData.diffSpending!)
                        )}`
                      : `- ${setDecimalDigits(
                          Math.abs(topCards.topCardData.diffSpending!)
                        )}`
                  } €`}
                  percent={`${setDecimalDigits(
                    topCards.topCardData.diffSpendingPercent!
                  )}%`}
                  icon={ChargeIcon}
                  iconEtat={
                    topCards.topCardData.diffSpending! > 0
                      ? TopIcon
                      : BottomIcon
                  }
                  isEntrep={
                    (company ? company : userCompany)?.pack ===
                    AppPacks.Entrepreneur
                  }
                  entrepMessage={
                    "* Le montant d'imposition précis vous sera communiqué par l'URSSAF"
                  }
                  className={`card-secondary ${
                    ![AppPacks.BNCAssoc, AppPacks.Entrepreneur].includes(
                      (company ? company : userCompany)
                        ?.pack as unknown as AppPacks
                    ) &&
                    !(
                      (company ? company : userCompany)?.pack ===
                        AppPacks.BICPlus &&
                      !(company ? company : userCompany).subjectToVAT
                    )
                      ? ""
                      : "resize-card-blacklist"
                  }`}
                />
              </Col>

              {![AppPacks.BNCAssoc, AppPacks.Entrepreneur].includes(
                (company ? company : userCompany)?.pack as unknown as AppPacks
              ) &&
              !(
                (company ? company : userCompany)?.pack === AppPacks.BICPlus &&
                !(company ? company : userCompany).subjectToVAT
              ) ? (
                <Col lg={4} sm={12}>
                  <CardDashboard
                    // title="AVANCE DE TVA"
                    title={
                      topCards.topCardData.VAT! > 0
                        ? "TVA À PAYER"
                        : "AVANCE DE TVA"
                    }
                    text={
                      setDecimalDigits(Math.abs(topCards.topCardData.VAT!)) +
                      " €"
                    }
                    icon={AvanceTvaIcon}
                    className="card-warning"
                  />
                </Col>
              ) : (
                <></>
              )}
            </Row>
          </div>
          <Card className="card-item-dash">
            <div className="title-dash-card">
              <h3>
                Courbe évolution du CA et des charges sur les 12 derniers mois
              </h3>
              <div className="legende-box">
                <span className="legende-item primary">Chiffre d’affaires</span>
                <span className="legende-item danger">Charges</span>
              </div>
            </div>
            <LineChartsMu caCahrtData={stats.caCahrtData} loading={loading} />
          </Card>
        </Col>
        <Col lg={4} md={12} sm={12}>
          {(company ? company : userCompany)?.pack !== AppPacks.Entrepreneur ? (
            <Card className="card-item-dash full-height">
              <h3>Top 3 des dépenses du mois</h3>
              {topCards.top3Data[0] && (
                <CardDashDepense
                  title={topCards.top3Data[0]?.main_categ!}
                  text={
                    `${setDecimalDigits(
                      Math.abs(topCards.top3Data[0]?.amount!)
                    )} €` || "0 €"
                  }
                  percent={
                    setDecimalDigits(
                      parseFloat(topCards.top3Data[0]?.percentage!)
                    ) || "0"
                  }
                  icon={
                    topCards.top3Data[0]?.iconLink?.key
                      ? topCards.top3Data[0]?.iconLink?.url
                      : EncaissemIcon
                  }
                  className="card-purple"
                />
              )}

              {topCards.top3Data[1] && (
                <CardDashDepense
                  title={topCards.top3Data[1]?.main_categ!}
                  text={
                    `${setDecimalDigits(
                      Math.abs(topCards.top3Data[1]?.amount!)
                    )} €` || "0 €"
                  }
                  percent={
                    setDecimalDigits(
                      parseFloat(topCards.top3Data[1]?.percentage!)
                    ) || "0"
                  }
                  icon={
                    topCards.top3Data[1]?.iconLink?.key
                      ? topCards.top3Data[1]?.iconLink?.url
                      : IconImmobilier
                  }
                  className="card-danger"
                />
              )}

              {topCards.top3Data[2] && (
                <CardDashDepense
                  title={topCards.top3Data[2]?.main_categ!}
                  text={
                    `${setDecimalDigits(
                      Math.abs(topCards.top3Data[2]?.amount!)
                    )} €` || "0 €"
                  }
                  percent={
                    setDecimalDigits(
                      parseFloat(topCards.top3Data[2]?.percentage!)
                    ) || "0"
                  }
                  icon={
                    topCards.top3Data[2]?.iconLink?.key
                      ? topCards.top3Data[2]?.iconLink?.url
                      : PaieDiffert
                  }
                  className="card-primary"
                />
              )}
            </Card>
          ) : (
            <Card className="card-item-dash full-height">
              <h3>Chiffre d’affaires / Seuil</h3>
              <Thermometer
                activity={(company ? company : userCompany)?.activity}
                value={stats.annualSoldeTresData.annualSoldeTresValue || 0}
              />
            </Card>
          )}
        </Col>

        {(company ? company : userCompany)?.pack !== AppPacks.Entrepreneur ? (
          <>
            <Col lg={6} md={12} sm={12}>
              <Card className="card-item-dash full-height">
                <h3 className="mb-5">Estimation résultat mensuel</h3>

                <DepenseCharts
                  monthlyEstimationData={stats.monthlyEstimationData}
                  loading={loading}
                />
              </Card>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <Card className="card-item-dash full-height">
                <h3>Solde de trésorie depuis janvier {moment().get("year")}</h3>
                <span className="price-solde">
                  {setDecimalDigits(
                    stats.annualSoldeTresData.annualSoldeTresValue || 0
                  )}{" "}
                  €
                </span>
                <LineCharts
                  annualSoldeTresData={stats.annualSoldeTresData}
                  loading={loading}
                />
              </Card>
            </Col>

            <Col sm={12}>
              <Card className="card-item-dash">
                <h3 className="text-center mb-3">
                  Prélèvements personels,cotisations,impôts et taxes réglés en{" "}
                  {moment().get("year")}
                </h3>
                {!loading ? (
                  <div className="chart-box">
                    {/* <CircleCharts spendingByCategoryData={spendingByCategoryData} /> */}
                    <Circles
                      spendingByCategoryData={stats.spendingByCategoryData}
                    />
                  </div>
                ) : (
                  <Spinner color="light" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                )}
              </Card>
            </Col>
          </>
        ) : (
          <></>
        )}
      </Row>
    </div>
  );
};

export default React.memo(Dashboard);
