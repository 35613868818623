import {useEffect, useState} from "react";
import GestionBridgeAccount from "../../AccountsChoice/GestionBridgeAccount/GestionBridgeAccount";
import {IBridgeAccount} from "../../../interfaces";
import {getBridgeAccount} from "../../../api/bridgeAccount";
import useAxios from "../../../util/hooks/useAxios";
import {toast} from "react-toastify";
import {toastSettings} from "../../../util/context";
import {useOutletContext} from "react-router";
import "./GestionCompteIban.scss";

const GestionCompteIban = () => {
  const {context, company, callback, client} = useOutletContext() as any;

  let api = useAxios();

  const [ibanList, setIbanList] = useState<IBridgeAccount[]>([]);

  const getBridgeAccountList = async () => {
    try {
      const {data} = await getBridgeAccount({clientId: client?.client_id, api});
      setIbanList(data.data);
    } catch (error) {
      toast.dismiss();
      toast.error("votre opération a échoué", toastSettings);
    }
  };
  console.log();

  useEffect(() => {
    if (client?.client_id) {
      getBridgeAccountList();
    }
  }, [client?.client_id]);

  return (
    <div className="container">
      <div className="section-Compte-sync">
        <h3>Gestion des comptes</h3>
        <GestionBridgeAccount
          ibans={ibanList}
          getBridgeAccounts={getBridgeAccountList}
          clientId={client?.client_id ?? ""}
        />
      </div>
    </div>
  );
};

export default GestionCompteIban;
