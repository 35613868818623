import React, { useEffect, useState } from "react";
import "./Tickets.scss";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Badge,
} from "reactstrap";

import UserInfo from "../../components/user-info/UserInfo";
import { colors, data } from "../../constants";
import { RiCalendar2Line, RiUser3Line } from "react-icons/ri";
import BannerTop from "../../components/Banner/BannerTop";

import Select from "react-select";

import { BootyPagination } from "../../components/table/pagination";
import {
  BsClockHistory,
  BsPatchExclamation,
  BsTrash,
  BsX,
} from "react-icons/bs";
import DataTable from "react-data-table-component";
import UserIconLead from "../../assets/images/svg/user-lead.svg";

import {
  Company,
  IApplication,
  ITicketNotif,
  IPermission,
  ITicket,
  SocketProps,
  User,
} from "../../interfaces";

import EmailIconBlue from "../../assets/images/svg/mail-blue.svg";
import PhoneIconBleu from "../../assets/PhoneIcon";
import CheckIcon from "../../assets/CheckIcon";
import Status from "../../components/badge/Status";
import LoadBoxIcon from "../../assets/LoadBoxIcon";
import ViewIcon from "../../assets/ViewIcon";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import config from "../../config";
import {
  setCounter,
  setErrorMessage,
  setReloadTickets,
} from "../../store/reducers/utils/utilSlice";
import { ErrorLogger } from "../../util/errorLogger";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import moment from "moment";
import "moment/locale/fr";
import { OptionType, TICKET_OPTIONS, UserTypes } from "../../util/context";
import { useNavigate } from "react-router-dom";
import {
  closeNewNotifBadge,
  IMessageNotifs,
  resetTicketNotifs,
  setTicketNotifs,
} from "../../store/reducers/messages-notifs/messagesNotifsSlice";
import { Dispatch } from "@reduxjs/toolkit";
import CalenderIconBlue from "../../assets/images/svg/calender-icon-blue.svg";
import { useFormatter } from "../../util/hooks/useFormatter";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import useAxios from "../../util/hooks/useAxios";
import { SiPypy } from "react-icons/si";
import CrownIcon from "../../assets/CrownIcon";
registerLocale("fr", fr);

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const { API_URL } = config[process.env.NODE_ENV];

type SearchTicketsFormValues = {
  dateFrom?: string | null;
  dateTo?: string | null;
  company?: OptionType | null;
  status?: OptionType | null;
};

interface DynamicNotifs<T> {
  [key: string]: T;
}

const Tickets = () => {
  const [open, setOpen] = useState(false);
  const [tickets, setTickets] = useState<Array<ITicket>>([]);
  const [loading, setLoading] = useState(false);

  const ontoggle = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  let api = useAxios();

  const deleteNotifications = async (ticket_id: string) => {
    try {
      await api.post(
        `/api/CustomNotification/deleteWhere`,
        {
          where: {
            ticket_id,
            user_id: creds.user_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      dispatch(closeNewNotifBadge());
      dispatch(
        resetTicketNotifs({
          ticket_id,
        })
      );
    } catch (error: any) {
      ErrorLogger("deleting notifications", error);
    }
  };

  const { dateSort } = useFormatter();

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { clientsList } = useSelector(
    (state: { clientsList: Company[] }) => state.clientsList
  ) as unknown as { clientsList: Company[] };

  const { companiesList } = useSelector(
    (state: { companiesList: Company[] }) => state.companiesList
  ) as unknown as { companiesList: Company[] };

  const { user } = useSelector(
    (state: { user: object; application: object; permissions: object }) =>
      state.user
  ) as { user: User; application: IApplication; permissions: IPermission[] };

  const messagesNotifs = useSelector(
    (state: { messagesNotifs: object }) => state.messagesNotifs
  ) as IMessageNotifs;

  const { newNotif } = useSelector(
    (state: { messagesNotifs: object }) => state.messagesNotifs
  ) as { newNotif: boolean };

  const { reloadTickets } = useSelector(
    (state: { util: object }) => state.util
  ) as { reloadTickets: boolean };

  const [pending, setPending] = useState<boolean>(true);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const columns: any = [
    {
      name: "Nº Ticket",
      sortable: true,
      cell: (row: any) => {
        return (
          <span className="ticket-id">
            {row.id.toUpperCase()}
            {messagesNotifs.tickets[`${row.id}`] &&
              messagesNotifs.tickets[`${row.id}`].length > 0 && (
                <Badge className="message-badge" color="warning">
                  {messagesNotifs.tickets[`${row.id}`].length}
                </Badge>
              )}
          </span>
        );
      },
    },
    {
      name: "Entreprise",
      selector: (row: any) => row.company.name || row.company.email,
      sortable: true,
    },
    {
      name: "Client",
      selector: (row: any) => row.user.firstName,
    },
    {
      name: "Statut",
      selector: (row: any) => row.status,
      sortable: true,
      cell: (row: ITicket) => (
        <>
          {row.status === 100 && (
            <Status className="btn" type="success" title="Résolue">
              <CheckIcon />
            </Status>
          )}
          {row.status === 107 && (
            <Status className="btn" type="danger" title="Non résolue">
              <BsX color="white" />
            </Status>
          )}
          {row.status === 102 && (
            <Status className="btn" type="warning" title="En attente">
              <BsClockHistory color="white" />
            </Status>
          )}
          {row.status === 106 && (
            <Status className="btn" type="primary" title="Nouveau">
              <BsPatchExclamation color="white" />
            </Status>
          )}
          {row.status === 999 && (
            <Status className="btn" type="dark" title="Deleted">
              <BsTrash color="white" />
            </Status>
          )}
        </>
      ),
    },
    {
      name: "Date de création",
      selector: (row: any) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      sortFunction: dateSort,
    },
    {
      button: true,
      cell: (row: Partial<ITicket>) => (
        <div className="table-action">
          <button
            className="btn btn-blue"
            onClick={async () => {
              await deleteNotifications(row.id as string);
              navigate(`/ticket/${row.id}`);
            }}
          >
            <ViewIcon />
          </button>
        </div>
      ),
    },
  ];

  const {
    control: searchControl,
    register: searchRegister,
    handleSubmit: searchHandleSubmit,
    reset: searchReset,
    formState: { errors: searchErrors },
  } = useForm<SearchTicketsFormValues>({});

  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = async (page: number) => {
    // await getTickets(page);
    setCurrentPage(page);
  };

  const getTickets = async () => {
    try {
      let customWhere: any = {
        perPage: 20,
        pageIndex: currentPage,
      };
      switch (creds.role) {
        case UserTypes.Cabinet:
          customWhere = {
            ...customWhere,
            where: {
              company_id: clientsList.reduce(
                (acc, curr) => [...acc, curr.id],
                [] as Array<string>
              ),
            },
          };
          break;
        case UserTypes.Client:
          customWhere = {
            ...customWhere,
            where: {
              company_id: creds.company_id,
            },
          };
          break;
        default:
          break;
      }

      const { data } = await api.post(`/api/Ticket/All`, customWhere, {
        headers: {
          "x-access-token": creds.token,
        },
      });
      setTickets(
        data.data.sort((a: { createdAt: number }, b: { createdAt: number }) => {
          return moment(b.createdAt).diff(moment(a.createdAt));
        })
      );
      for (let tick of data.data) {
        if (String(tick.deletedAt) !== "null") {
          const userNotifs = tick.notifications.filter(
            (elt: { user_id: string }) => {
              return elt.user_id === creds.user_id;
            }
          );
          dispatch(
            setTicketNotifs({
              ticket: tick.id,
              notifications: userNotifs,
            })
          );
        }
      }
      setTotalRows(data.count);
      if (reloadTickets) {
        dispatch(setReloadTickets(false));
      }
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("getting tickets", error);
    }
  };

  useEffect(() => {
    if (currentPage || reloadTickets || creds.company_id) {
      getTickets();
    }
  }, [currentPage, reloadTickets, creds.company_id]);

  const resetSearchForm = () => {
    searchReset({
      dateFrom: null,
      dateTo: null,
      company: null,
      status: null,
    });
    getTickets();
  };

  const searchTickets: SubmitHandler<SearchTicketsFormValues> = async (
    form: SearchTicketsFormValues
  ) => {
    try {
      setLoading(true);
      setPending(true);
      let payload: any = {};
      if (form.dateFrom || form.dateTo)
        payload.createdAt = {
          from: form.dateFrom
            ? moment(form.dateFrom).format()
            : moment("2019/01/01").format(),
          to: form.dateTo
            ? moment(form.dateTo).add(1, "day").format()
            : moment().add(1, "day").format(),
        };

      if (creds.role === UserTypes.Client) {
        payload.company_id = creds.company_id;
      } else {
        if (form.company) {
          payload.company_id = form.company.value;
        }
      }
      if (form.status) payload.status = form.status.value;

      const { data } = await api.post(
        `/api/Ticket/All`,
        {
          where: {
            ...payload,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setTickets(data.data);
      setLoading(false);
      setPending(false);
    } catch (error: any) {
      setPending(false);
      ErrorLogger("searching emails in mailing", error);
    }
  };

  // search ticket form
  const { ref: dateToRef, ...dateTo } = searchRegister("dateTo");
  const { ref: dateFromRef, ...dateFrom } = searchRegister("dateFrom");

  return (
    <div className="page page-tickets">
      <div
        className={`top-content ${
          creds.role === UserTypes.Client ? "client-resize-header" : ""
        }`}
      >
        <Row>
          <Col lg={8} md={12} className="blockBanner">
            <BannerTop banner={data.tickets} />
          </Col>
          <Col lg={4} md={12}>
            <div
              className={`${
                user.role === UserTypes.Client ? "card blockUser" : ""
              } ticket-profile-wrapper`}
            >
              {user.id && user.role === UserTypes.Client ? (
                <>
                  <Button
                    className="btn-icon btn-icon-primary"
                    color="light"
                    onClick={() => navigate("/tickets")}
                  >
                    {newNotif && <Badge color="primary"> </Badge>}
                    <CrownIcon stroke={colors.primaryColor} />
                  </Button>
                </>
              ) : (
                <></>
              )}
              <UserInfo user={user} />
              {user.id && user.role === UserTypes.Client ? (
                <>
                  <Button
                    className="btn-icon btn-icon-primary"
                    color="light"
                    onClick={() =>
                      navigate({
                        pathname: "/profil/client",
                        // search: "?page=1&tab=1",
                      })
                    }
                  >
                    <RiUser3Line />
                  </Button>
                </>
              ) : (
                <></>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <Card className="card-filter">
        <form onSubmit={searchHandleSubmit(searchTickets)}>
          <Row className="align-items-end">
            <Col lg={8} md={12}>
              <div className="filterInner">
                <Row>
                  <Col lg={creds?.role !== UserTypes.Client ? 3 : 4} sm={6}>
                    <FormGroup className="form-icon icon-end">
                      <Label for="dated">Date de début</Label>
                      <Controller
                        control={searchControl}
                        name="dateFrom"
                        render={({ field }) => (
                          <DatePicker
                            placeholderText="Date de début"
                            onChange={(date: any) => field.onChange(date)}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-secondary"
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      {/* <Input
                        id="dated"
                        {...dateFrom}
                        innerRef={dateFromRef}
                        placeholder="Date de début"
                        type="date"
                        className="form-secondary"
                      /> */}
                      <span className="icon icon-secondary ">
                        <img src={CalenderIconBlue} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col lg={creds?.role !== UserTypes.Client ? 3 : 4} sm={6}>
                    <FormGroup className="form-icon icon-end">
                      <Label for="datef">Date de fin</Label>
                      <Controller
                        control={searchControl}
                        name="dateTo"
                        render={({ field }) => (
                          <DatePicker
                            placeholderText="Date de fin"
                            onChange={(date: any) => field.onChange(date)}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-secondary"
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      {/* <Input
                        id="datef"
                        {...dateTo}
                        innerRef={dateToRef}
                        placeholder="Date de fin"
                        type="date"
                        className="form-secondary"
                      /> */}
                      <span className="icon icon-secondary ">
                        <img src={CalenderIconBlue} alt="icon" />
                      </span>
                    </FormGroup>
                  </Col>
                  <Col lg={creds?.role !== UserTypes.Client ? 3 : 4} sm={6}>
                    <FormGroup>
                      <Label for="exampleEmail">Statut</Label>
                      <Controller
                        name="status"
                        control={searchControl}
                        render={({ field }) => (
                          <Select
                            {...field}
                            closeMenuOnSelect={true}
                            options={TICKET_OPTIONS}
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  {creds?.role !== UserTypes.Client && (
                    <Col lg={3} sm={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Entreprise</Label>
                        <Controller
                          name="company"
                          control={searchControl}
                          render={({ field }) => (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              options={
                                user.role === UserTypes.Cabinet
                                  ? clientsList.reduce(
                                      (acc, curr) => [
                                        ...acc,
                                        {
                                          label: curr.name || curr.email,
                                          value: curr.id,
                                        },
                                      ],
                                      [] as OptionType[]
                                    )
                                  : companiesList.reduce(
                                      (acc, curr) => [
                                        ...acc,
                                        {
                                          label: curr.name || curr.email,
                                          value: curr.id,
                                        },
                                      ],
                                      [] as OptionType[]
                                    )
                              }
                              classNamePrefix="select"
                              className="custom-select form-secondary"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
            <Col lg={4} md={12}>
              <div className="actionsFilter mb-3">
                <Button color="secondary" type="submit">
                  <span>Filtrer</span>
                </Button>
                <Button
                  color="secondary"
                  outline
                  type="button"
                  onClick={() => resetSearchForm()}
                >
                  <span>Réinitialiser</span>
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </Card>
      <div>
        <Card className="card-Table table-primary">
          <DataTable
            columns={columns}
            data={tickets}
            pagination
            noDataComponent={<p>Il n'y a aucun data à afficher</p>}
            onRowClicked={async (row: ITicket, e: any) => {
              await deleteNotifications(row.id as string);
              navigate(`/ticket/${row.id}`);
            }}
            paginationComponent={(props) => {
              const customProps = { ...props, color: "primary" };
              return <BootyPagination {...customProps} />;
            }}
            progressPending={pending}
            progressComponent={
              <>
                <Spinner color="secondary" type="grow" className="mx-1">
                  Loading...
                </Spinner>
                <Spinner color="secondary" type="grow" className="mx-1">
                  Loading...
                </Spinner>
                <Spinner color="secondary" type="grow" className="mx-1">
                  Loading...
                </Spinner>
              </>
            }
            paginationServer
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            paginationPerPage={20}
          />

          <div className="openbtn text-center">
            <Modal
              className="modal-secondary modal-dialog-centered modal-lg"
              isOpen={open}
              toggle={() => setOpen(false)}
            >
              <ModalHeader toggle={() => setOpen(false)}>
                Créer un nouveau lead
              </ModalHeader>
              <ModalBody>
                <div className="content-form-block">
                  <Row>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="name">Nom</Label>
                        <Input
                          id="name"
                          name="name"
                          placeholder="Nom"
                          type="text"
                          className="form-secondary"
                        />
                        <span className="icon icon-secondary ">
                          <img src={UserIconLead} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="prenom">Prénom</Label>
                        <Input
                          id="prenom"
                          name="prenom"
                          placeholder="Prénom"
                          type="text"
                          className="form-secondary"
                        />
                        <span className="icon icon-secondary ">
                          <img src={UserIconLead} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="email">Email</Label>
                        <Input
                          id="email"
                          name="prenom"
                          placeholder="Email"
                          type="text"
                          className="form-secondary"
                        />
                        <span className="icon icon-secondary ">
                          <img src={EmailIconBlue} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="phone">Téléohone</Label>
                        <Input
                          id="phone"
                          name="phone"
                          placeholder="Téléohone"
                          type="text"
                          className="form-secondary"
                        />
                        <span className="icon icon-secondary ">
                          <PhoneIconBleu />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="pwd">Canal d'aquisition</Label>
                        <Select
                          options={options}
                          classNamePrefix="select"
                          className="custom-select form-secondary"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="cpwd">Statu</Label>
                        <Select
                          options={options}
                          classNamePrefix="select"
                          className="custom-select form-secondary"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="cpwd">Notes</Label>
                        <Input
                          className="form-secondary text-area-custom"
                          type="textarea"
                          rows="15"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="secondary"
                  outline
                  onClick={function noRefCheck() {}}
                >
                  Annuler
                </Button>
                <Button color="secondary" onClick={function noRefCheck() {}}>
                  Créer
                </Button>
              </ModalFooter>
            </Modal>

            {/* <Modal
              className="modal-warning modal-dialog-centered modal-lg"
              isOpen={open}
              toggle={() => setOpen(false)}
            >
              <ModalHeader toggle={() => setOpen(false)}>
                Créer un nouvel utilisateur
              </ModalHeader>
              <ModalBody>
                <div className="content-form-block">
                  <Row>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="name">Nom</Label>
                        <Input
                          id="name"
                          name="name"
                          placeholder="Nom"
                          type="text"
                          className="form-warning"
                        />
                        <span className="icon icon-warning ">
                          <img src={UserProsp} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="prenom">Prénom</Label>
                        <Input
                          id="prenom"
                          name="prenom"
                          placeholder="Prénom"
                          type="text"
                          className="form-warning"
                        />
                        <span className="icon icon-warning ">
                          <img src={UserProsp} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="email">Email</Label>
                        <Input
                          id="email"
                          name="prenom"
                          placeholder="Email"
                          type="text"
                          className="form-warning"
                        />
                        <span className="icon icon-warning ">
                          <img src={EmailIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="phone">Téléohone</Label>
                        <Input
                          id="phone"
                          name="phone"
                          placeholder="Téléohone"
                          type="text"
                          className="form-warning"
                        />
                        <span className="icon icon-warning ">
                          <img src={PhoneIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="pack">Pack</Label>
                        <Select
                          options={status}
                          closeMenuOnSelect={false}
                          isMulti
                          classNamePrefix="select"
                          className="custom-select form-warning"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="pack">Genre</Label>
                        <Select
                          options={status}
                          closeMenuOnSelect={false}
                          isMulti
                          classNamePrefix="select"
                          className="custom-select form-warning"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="pwd">Mot de passe utilisateur</Label>
                        <Input
                          id="pwd"
                          name="pwd"
                          placeholder="Mot de passe"
                          type="text"
                          className="form-warning"
                        />
                        <span className="icon icon-warning ">
                          <img src={KeyIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="cpwd">Confirmer le mot de passe</Label>
                        <Input
                          id="cpwd"
                          name="cpwd"
                          placeholder="Confirmer le mot de passe"
                          type="text"
                          className="form-warning"
                        />
                        <span className="icon icon-warning ">
                          <img src={KeyIcon} alt="icon" />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="pack">Application associée</Label>
                        <Select
                          options={status}
                          closeMenuOnSelect={false}
                          isMulti
                          classNamePrefix="select"
                          className="custom-select form-warning"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="warning"
                  outline
                  onClick={function noRefCheck() {}}
                >
                  Annuler
                </Button>
                <Button color="warning" onClick={function noRefCheck() {}}>
                  Enregistrer
                </Button>
              </ModalFooter>
            </Modal> */}
            {/* <Modal
              className="modal-danger"
              isOpen={open}
              toggle={() => setOpen(false)}
            >
              <ModalHeader toggle={() => setOpen(false)}>
                Supprimer un client
              </ModalHeader>
              <ModalBody>
                <div className="content-text p-5">
                  <p className="msg-text">
                    Vous êtes sur de vouloir supprimer le client Elans?
                  </p>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  outline
                  onClick={function noRefCheck() {}}
                >
                  Non
                </Button>
                <Button color="danger" onClick={function noRefCheck() {}}>
                  Oui
                </Button>
              </ModalFooter>
            </Modal> */}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Tickets;
