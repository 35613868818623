import React, { useEffect, useState } from "react";

import "../style.scss";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { useParams } from "react-router-dom";
import config from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { ErrorLogger } from "../../../util/errorLogger";
import { Company, IS3, User } from "../../../interfaces";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import UserProsp from "../../../assets/images/svg/user-blue.svg";
import UserChat from "../../../assets/images/avatar_empty.png";

import {
  RiBriefcase4Line,
  RiCalendar2Line,
  RiCameraLine,
  RiCommunityLine,
  RiDownload2Fill,
  RiFlagLine,
  RiMapPinLine,
} from "react-icons/ri";
import AttacheIcon from "../../../assets/AttacheIcon";
import moment from "moment";
import "moment/locale/fr";
import Select from "react-select";
import { GENDER_OPTIONS, OptionType, UserTypes } from "../../../util/context";
import EmailIcon from "../../../assets/images/svg/mail-blue.svg";
import PhoneIcon from "../../../assets/images/svg/phone-blue.svg";
import { BsMailbox, BsTrashFill } from "react-icons/bs";
import CalenderIconBlue from "../../../assets/images/svg/calender-icon-blue.svg";
import { ToastContainer, toast } from "react-toastify";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import useAxios from "../../../util/hooks/useAxios";
import FilePicker from "../../../components/FilePicker";
import { colors } from "../../../constants";
import CerleUserBg from "../../../assets/CerleUserBg";
import { fetchAllCompanies } from "../../../store/reducers/companies-list/companiesSlice";
import { fetchAllClients } from "../../../store/reducers/clients-list/clientsSlice";
registerLocale("fr", fr);
export interface TransactionsProps {
  context?: string;
  company?: Company;
  client?: User;
  callback?: () => void;
  user?: User;
}

export type GeneralFormValues = {
  id: string | null;
  username: string | null;
  proofOfIdentity: any;
  nationality: string | null;
  city: string | null;
  address: string | null;
  companyPosition: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  dateOfBirth: string | null;
  profilePhoto: string | null;
  phone: string | null;
  gender: OptionType | null;
  countryOfBirth?: string | null;
  postcodeOfBirth?: string | null;
};

const { API_URL } = config[process.env.NODE_ENV];

export const deleteFile = async (
  model: string,
  modelId: string,
  attribute: string,
  token: string,
  api: any
) => {
  try {
    const formData = new FormData();
    formData.append("id", modelId);
    formData.append(attribute, "");
    await api.patch(`/api/${model}/files`, formData, {
      headers: {
        "x-access-token": token,
      },
    });
  } catch (error: any) {
    ErrorLogger("deleteing file", error);
  }
};

export const deleteFileFromList = async (
  files: string[],
  attribute: string,
  model: string,
  modelId: string,
  token: string,
  api: any
) => {
  try {
    
    const { data } = await api.delete(`/api/${model}/files`, {
      data: {
        id: modelId,
        [attribute]: files,
      },
      headers: {
        "x-access-token": token,
      },
    });

    return data;
  } catch (error: any) {
    ErrorLogger("deliting file", error);
  }
};

const Information: React.FC<TransactionsProps> = ({
  context,
  company,
  client,
  callback,
}: {
  context?: string;
  company?: Company;
  client?: User;
  callback?: () => void;
}) => {
  const [currentUser, setCurrentUser] = useState<User>();
  const [loading, setLoading] = useState<boolean>(false);
  const [logoutModal, setLogoutModal] = useState<boolean>(false);
  const [userProfilePhoto, setUserProfilePhoto] = useState<any>();
  const [userCIN, setUserCIN] = useState<any>();
  const [cinLoading, setCinLoading] = useState<boolean>(false);
  let api = useAxios();

  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { user: connectedUser } = useSelector(
    (state: { root: object; user: object }) => state.user
  ) as { user: User };

  const { id } = useParams();

  const {
    control,
    setValue,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<GeneralFormValues>({});

  const { proofOfIdentity: _proofOfIdentity } = watch();

  const getUser = async () => {
    try {
      setCurrentUser(client);
      reset({
        username: client?.username,
        // proofOfIdentity: client?.proofOfIdentity,
        nationality: client?.nationality,
        city: client?.city,
        address: client?.address,
        companyPosition: client?.companyPosition,
        email: client?.email,
        firstName: client?.firstName,
        lastName: client?.lastName,
        dateOfBirth: client?.dateOfBirth,
        // profilePhoto: client?.profilePhoto.url,
        phone: client?.phone,
        gender: GENDER_OPTIONS.find((elt) => elt.value === client?.gender),
        postcodeOfBirth: client?.postcodeOfBirth,
        countryOfBirth: client?.countryOfBirth,
      });
      setUserProfilePhoto(client?.profilePhoto);
      setUserCIN(client?.proofOfIdentity);
    } catch (error: any) {
      ErrorLogger("getting client info", error);
    }
  };

  useEffect(() => {
    getUser();
  }, [client]);

  const dispatch = useDispatch();

  const { ref: lastNameRef, ...lastName } = register("lastName");
  const { ref: firstNameRef, ...firstName } = register("firstName");
  const { ref: usernameRef, ...username } = register("username");
  const { ref: proofOfIdentityRef, ...proofOfIdentity } =
    register("proofOfIdentity");
  const { ref: nationalityRef, ...nationality } = register("nationality");
  const { ref: cityRef, ...city } = register("city");
  const { ref: addressRef, ...address } = register("address");
  const { ref: companyPositionRef, ...companyPosition } =
    register("companyPosition");
  const { ref: emailRef, ...email } = register("email");
  const { ref: dateOfBirthRef, ...dateOfBirth } = register("dateOfBirth");
  const { ref: profilePhotoRef, ...profilePhoto } = register("profilePhoto");
  const { ref: phoneRef, ...phone } = register("phone");
  const { ref: genderRef, ...gender } = register("gender");
  const { ref: postcodeOfBirthRef, ...postcodeOfBirth } =
    register("postcodeOfBirth");

  const updateClient: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("id", currentUser?.id as string);

      // if (userCIN && !userCIN.key) {
      //   formData.append("proofOfIdentity", userCIN);
      // }

      if (userProfilePhoto && !userProfilePhoto.key) {
        formData.append("profilePhoto", userProfilePhoto);
      }

      if (form.nationality !== currentUser?.nationality) {
        formData.append("nationality", form.nationality as string);
      }

      if (form.firstName !== currentUser?.firstName) {
        formData.append("firstName", form.firstName as string);
      }

      if (form.city !== currentUser?.city) {
        formData.append("city", form.city as string);
      }

      if (form.address !== currentUser?.address) {
        formData.append("address", form.address as string);
      }

      if (form.companyPosition !== currentUser?.companyPosition) {
        formData.append("companyPosition", form.companyPosition as string);
      }

      if (form.countryOfBirth !== currentUser?.countryOfBirth) {
        formData.append("countryOfBirth", form.countryOfBirth as string);
      }

      if (
        form.dateOfBirth &&
        moment(form.dateOfBirth).format("dd/MM/yyyy") !==
          moment(currentUser?.dateOfBirth).format("dd/MM/yyyy")
      ) {
        formData.append(
          "dateOfBirth",
          moment(form.dateOfBirth).format("YYYY-MM-DD hh:mm")
        );
      }

      if (form.gender && form.gender.value !== currentUser?.gender) {
        formData.append("gender", form.gender.value as string);
      }

      if (form.lastName !== currentUser?.lastName) {
        formData.append("lastName", form.lastName as string);
      }

      if (form.phone !== currentUser?.phone) {
        formData.append("phone", form.phone as string);
      }

      if (form.email && form.email !== currentUser?.email) {
        formData.append("email", form.email);
      }

      if (form.postcodeOfBirth !== currentUser?.postcodeOfBirth) {
        formData.append("postcodeOfBirth", form.postcodeOfBirth as string);
      }

      await api.post(`/api/User/Update`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      callback!();
      if (creds.role === UserTypes.Admin) {
        dispatch(fetchAllCompanies());
      }
      if (creds.role === UserTypes.Cabinet) {
        dispatch(fetchAllClients(creds.user_id));
      }
      setLoading(false);
      toast.dismiss();
      toast.success("Votre modification a été effectuée avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      ErrorLogger("updating client form", error);
    }
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event: any) => {
    (hiddenFileInput.current as any).click();
  };

  const handleChange = (event: { target: { files: any[] } }) => {
    const fileUploaded = event.target.files[0];
    setUserProfilePhoto(fileUploaded);
  };

  const addDefaultSrc = (event: any) => {
    event.target.src = UserChat;
  };

  return (
    <>
      <div className="form__container">
        
        <form onSubmit={handleSubmit(updateClient)}>
          <Row>
            <Col lg={2} md={4} className="profile__photo">
              <div className={`user-info mb-4 client-profile-pic`}>
                <CerleUserBg
                  className={"bgCercle"}
                  fillColor={colors.secondaryColor}
                />
                <div className="user-info__img">
                  {userProfilePhoto ? (
                    <img
                      src={
                        (userProfilePhoto as any).url
                          ? (userProfilePhoto as any).url
                          : URL.createObjectURL(userProfilePhoto as any)
                      }
                      alt="img"
                      onError={addDefaultSrc}
                    />
                  ) : (
                    <img src={UserChat} alt="img" onError={addDefaultSrc} />
                  )}
                </div>
              </div>
              <Button
                type="button"
                className="form__button"
                color="secondary"
                onClick={handleClick}
              >
                <RiCameraLine className="icon-btn" />
                Changer photo
              </Button>

              <input
                ref={hiddenFileInput}
                onChange={handleChange as any}
                type="file"
                className="file-input"
              />
            </Col>
            <Col lg={10} md={8}>
              <Row>
                <Col lg={4} md={6}>
                  <FormGroup className="form-icon icon-start">
                    <Label for="gender">Civilité</Label>
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={GENDER_OPTIONS}
                          closeMenuOnSelect={true}
                          classNamePrefix="select"
                          className="custom-select form-secondary"
                        />
                      )}
                    />
                    {/* <Input
                    id="gender"
                    innerRef={genderRef}
                    {...gender}
                    type="text"
                    className="form-secondary"
                  /> */}
                  </FormGroup>
                </Col>
                <Col lg={4} md={6}>
                  <FormGroup className="form-icon icon-start">
                    <Label for="firstName">Prènom</Label>
                    <Input
                      id="firstName"
                      innerRef={firstNameRef}
                      {...firstName}
                      type="text"
                      className="form-secondary"
                    />
                    <span className="icon icon-secondary ">
                      <img src={UserProsp} alt="icon" />
                    </span>
                  </FormGroup>
                </Col>
                <Col lg={4} md={6}>
                  <FormGroup className="form-icon icon-start">
                    <Label for="lastName">Nom</Label>
                    <Input
                      id="lastName"
                      innerRef={lastNameRef}
                      {...lastName}
                      type="text"
                      className="form-secondary"
                    />
                    <span className="icon icon-secondary ">
                      <img src={UserProsp} alt="icon" />
                    </span>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={6}>
                  <FormGroup className="form-icon icon-start">
                    <Label for="email">Email</Label>
                    <Input
                      id="email"
                      innerRef={emailRef}
                      {...email}
                      type="text"
                      className="form-secondary"
                    />
                    <span className="icon icon-secondary ">
                      <img src={EmailIcon} alt="icon" />
                    </span>
                  </FormGroup>
                </Col>
                <Col lg={4} md={6}>
                  <FormGroup className="form-icon icon-start">
                    <Label for="phone">Téléphone</Label>
                    <Input
                      id="phone"
                      innerRef={phoneRef}
                      {...phone}
                      type="text"
                      className="form-secondary"
                    />
                    <span className="icon icon-secondary ">
                      <img src={PhoneIcon} alt="icon" />
                    </span>
                  </FormGroup>
                </Col>
                <Col lg={4} md={6}>
                  <FormGroup className="form-icon icon-end">
                    <Label for="dateOfBirth">Date de naissance</Label>
                    <Controller
                      control={control}
                      name="dateOfBirth"
                      render={({ field }) => (
                        <DatePicker
                          placeholderText="Date de début"
                          onChange={(date: any) => field.onChange(date)}
                          selected={field.value ? new Date(field.value) : null}
                          className="form-control form-secondary"
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown
                          showMonthDropdown
                          locale="fr"
                          dateFormat="dd/MM/yyyy"
                        />
                      )}
                    />
                    {/* <Input
                      id="dateOfBirth"
                      innerRef={dateOfBirthRef}
                      {...dateOfBirth}
                      type="date"
                      className="form-secondary"
                    /> */}
                    <span className="icon icon-secondary ">
                      <img src={CalenderIconBlue} alt="icon" />
                    </span>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={6}>
                  <FormGroup className="form-icon icon-start">
                    <Label for="nationality">Pays</Label>
                    <Input
                      id="nationality"
                      innerRef={nationalityRef}
                      {...nationality}
                      type="text"
                      className="form-secondary"
                    />
                    <span className="icon icon-secondary ">
                      <RiFlagLine />
                    </span>
                  </FormGroup>
                </Col>
                <Col lg={4} md={6}>
                  <FormGroup className="form-icon icon-start">
                    <Label for="city">Ville</Label>
                    <Input
                      id="city"
                      innerRef={cityRef}
                      {...city}
                      type="text"
                      className="form-secondary"
                    />
                    <span className="icon icon-secondary ">
                      <RiCommunityLine />
                    </span>
                  </FormGroup>
                </Col>
                <Col lg={4} md={6}>
                  <FormGroup className="form-icon icon-start">
                    <Label for="postcodeOfBirth">
                      Code postal de naissance
                    </Label>
                    <Input
                      id="postcodeOfBirth"
                      innerRef={postcodeOfBirthRef}
                      {...postcodeOfBirth}
                      type="text"
                      className="form-secondary"
                    />
                    <span className="icon icon-secondary ">
                      <BsMailbox />
                    </span>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={4} md={6}>
                  <FormGroup className="form-icon icon-start">
                    <Label for="address">Adresse</Label>
                    <Input
                      id="address"
                      innerRef={addressRef}
                      {...address}
                      type="text"
                      className="form-secondary"
                    />
                    <span className="icon icon-secondary ">
                      <RiMapPinLine />
                    </span>
                  </FormGroup>
                </Col>
                <Col lg={4} md={6}>
                  <FormGroup className="form-icon icon-start">
                    <Label for="companyPosition">
                      Poste au sein de l'entreprise
                    </Label>
                    <Input
                      id="companyPosition"
                      innerRef={companyPositionRef}
                      {...companyPosition}
                      type="text"
                      className="form-secondary"
                    />
                    <span className="icon icon-secondary ">
                      <RiBriefcase4Line />
                    </span>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="form__footer">
            <Button
              className="form__button"
              color="secondary"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <Spinner color="light" type="border" size={"sm"}>
                  Loading...
                </Spinner>
              ) : (
                "Enregistrer"
              )}
            </Button>
          </Row>
        </form>
      </div>
    </>
  );
};

export default Information;
