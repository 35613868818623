import React, { useEffect, useState } from "react";
import "./main-layout.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import TopNav from "../components/topnav/TopNav";
import { useDispatch, useSelector } from "react-redux";
import { ErrorLogger } from "../util/errorLogger";
import { IBridgeItem, SocketProps } from "../interfaces";
import { toast, ToastContainer } from "react-toastify";
import { UserTypes } from "../util/context";
import useAxios from "../util/hooks/useAxios";

export interface LayoutProps {}

const MainLayout: React.FC<SocketProps> = ({ socket }) => {
  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  let api = useAxios();

  const [menuOpen, setMenuOpen] = useState(false);

  const location = useLocation();

  const navigate = useNavigate();

  const Msg = () => (
    <div>
      Vous avez synchronisé votre compte avec un compte Qonto. Veuillez insérer
      les données correspondantes pour chaque organisation Qonto{" "}
      <strong onClick={() => navigate("/profil/entreprise/sync")}>Ici</strong>.
    </div>
  );

  const checkIfQontoUser = async () => {
    try {
      const { data: qontoAccounts } = await api.post(
        `/api/BridgeItem/All`,
        {
          where: {
            companyId: creds.company_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const { data: syncOrganizations } = await api.post(
        `/api/SyncOrganization/All`,
        {
          where: {
            bankId: 421,
            companyId: creds.company_id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const items = qontoAccounts.data as IBridgeItem[];

      if (
        items.length > 0 &&
        items.find((elt: { bankId: number }) => elt.bankId === 421) &&
        syncOrganizations.data.length === 0
      ) {
        toast.info(<Msg />, {
          theme: "colored",
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: "qontoNotif",
        });
      }
    } catch (error: any) {
      ErrorLogger("getting indemnities", error);
    }
  };

  useEffect(() => {
    if (creds.role === UserTypes.Client && location.pathname === "/") {
      checkIfQontoUser();
    }
  }, []);

  return (
    <div
      className={`main${creds.role !== UserTypes.Client ? "__admin" : ""} ${
        menuOpen ? "menuOpen" : "menuColsed"
      }`}
    >
      <Sidebar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div className="main__content">
        <TopNav socket={{ socket }} />
        <Outlet context={{ socket }} />
      </div>
    </div>
  );
};

export default MainLayout;
